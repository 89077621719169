// This optional code is used to register a service worker.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const register = (config) => {
  if ("serviceWorker" in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config);
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
};

const registerValidSW = async (swUrl, config) => {
  const registration = await navigator.serviceWorker.register(swUrl);
  registration.onUpdateFound = () => {
    const installingWorker = registration.installing;
    if (installingWorker == null) {
      return;
    }
    installingWorker.onStateChange = () => {
      if (installingWorker.state === "installed") {
        if (navigator.serviceWorker.controller) {
          // At this point, the updated precached content has been fetched,
          // but the previous service worker will still serve the older
          // content until all client tabs are closed.
          console.log(
            "New content is available and will be used when all " +
              "tabs for this page are closed. See https://bit.ly/CRA-PWA."
          );

          installingWorker.postMessage({ action: "skipWaiting" });
          window.location.reload();

          // Execute callback
          if (config && config.onUpdate) {
            config.onUpdate(registration);
          }
        } else {
          // At this point, everything has been precached.
          // It's the perfect time to display a
          // "Content is cached for offline use." message.
          console.log("Content is cached for offline use.");

          // Execute callback
          if (config && config.onSuccess) {
            config.onSuccess(registration);
          }
        }
      }
    };
  };
  const subscription = await registration.pushManager.getSubscription();

  if (!subscription) {
    subscribe();
  } else {
    callSubscribe(subscription);
  }
};

const checkValidServiceWorker = async (swUrl, config) => {
  // Check if the service worker can be found. If it can't reload the page.
  const response = await fetch(swUrl);
  // Ensure service worker exists, and that we really are getting a JS file.
  const contentType = response.headers.get("content-type");
  if (
    response.status === 404 ||
    (contentType != null && contentType.indexOf("javascript") === -1)
  ) {
    // No service worker found. Probably a different app. Reload the page.
    const registration = navigator.serviceWorker.ready;
    await registration.unregister();
    window.location.reload();
  } else {
    // Service worker found. Proceed as normal.
    registerValidSW(swUrl, config);
  }
};

const subscribe = async () => {
  const registration = await navigator.serviceWorker.ready;
  const vapidPublicKey =
    "BA0LFe-_390V_T7_qRinL4hyeJlN6pZio6frBqmmGIdoQvw9CgOrqE-B2u0AogEUi1jqUI5Sm7K6Np4BijyraoM";

  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlB64ToUint8Array(vapidPublicKey),
  });
  callSubscribe(subscription);
};

const urlB64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  /* eslint-disable no-useless-escape */
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  /* eslint-enable */
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const retry = (fn) =>
  new Promise((resolve) => {
    fn()
      .then(resolve)
      .catch(() => {
        setTimeout(() => {
          retry(fn).then(resolve);
        }, 1000);
      });
  });

const getToken = () => {
  if (localStorage.getItem("token")) {
    return Promise.resolve(localStorage.getItem("token"));
  }
  return Promise.reject(false);
};

const callSubscribe = async (subscription) => {
  const token = await retry(getToken);
  const h = new Headers({ Accept: "application/json" });
  h.append("Authorization", `Bearer ${token}`);
  const response = await fetch("/notifications/subscribe", {
    method: "post",
    headers: h,
    body: JSON.stringify({ subscription: JSON.stringify(subscription) }),
  });
  if (response.status !== 204) {
    console.log("failed to subscribe for push notifications");
  }
};

export const unregister = async () => {
  if ("serviceWorker" in navigator) {
    const registration = await navigator.serviceWorker.ready;
    registration.unregister();
  }
};

export const convertDateWithTimeFormat = (date) => {
  const dateObject = new Date(date);
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return dateObject.toLocaleDateString(undefined, options);
};

export const convertDateFormat = (date) => {
  const dateObject = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return dateObject.toLocaleDateString(undefined, options);
};

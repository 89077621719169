import "./Sidebar.css";
import React from "react";
import SidebarLink from "./sidebarLink";
import AssignmentIcon from "@material-ui/icons/Assignment";
import HistoryIcon from "@material-ui/icons/History";

class Sidebar extends React.Component {
  render() {
    return (
      <div className="Sidebar">
        <nav className="Sidebar__nav">
          <SidebarLink to="/tasks" icon={<AssignmentIcon />}>
            Tasks
          </SidebarLink>
          <SidebarLink to="/tasksHistory" icon={<HistoryIcon />}>
            Tasks History
          </SidebarLink>
        </nav>
      </div>
    );
  }
}

export default Sidebar;

import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import _ from "lodash";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.spacing(50),
    maxHeight: theme.spacing(10),
    overflow: "auto",
    cursor: "pointer",
  },
}));

export default ({ record, name }) => {
  const classes = useStyles();
  let link = _.get(record, name);
  return (
    <Link href={link} target="_blank" rel="noopener noreferrer">
      <Typography className={classes.root}>{link}</Typography>
    </Link>
  );
};

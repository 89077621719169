import "./SidebarLink.css";

import React from "react";
import { NavLink } from "react-router-dom";
import Tooltip from "tombac-ui/dist/components/Tooltip/Tooltip";

function SidebarLink({ icon, children, ...props }) {
  const tooltip = () => <div className="SidebarLink__tooltip">{children}</div>;

  const tooltipTrigger = ({ ref, open, close }) => (
    <NavLink
      {...props}
      className="SidebarLink"
      activeClassName="SidebarLink--active"
    >
      <span
        ref={ref}
        onMouseEnter={open}
        onMouseLeave={close}
        className="SidebarLink__inner"
      >
        <div className="SidebarLink__icon">{icon}</div>
      </span>
    </NavLink>
  );

  return (
    <Tooltip placement="right" content={tooltip}>
      {tooltipTrigger}
    </Tooltip>
  );
}

export default SidebarLink;

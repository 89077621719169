import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { createStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";

const styles = createStyles({
  root: {
    width: "100%",
  },
});

const CustomTable = ({
  records,
  classes,
  basePath,
  columns,
  workPeriodId,
  setActiveTask,
  activeTask,
  reload,
}) => {
  return (
    <Paper className={classes.root}>
      <Table>
        <TableHead>
          <TableRow key="headers">
            {columns.map((column) => {
              return (
                <TableCell
                  key={`${column.label}_${column.id}`}
                  align={column.align}
                >
                  {column.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((record) => {
            return (
              <TableRow hover tabIndex={-1} key={`${record.id}_tableRow`}>
                {columns.map((column) => {
                  return (
                    <TableCell
                      key={`${column.id}_${record.id}`}
                      align={column.align}
                    >
                      {column.component({
                        record,
                        basePath,
                        workPeriodId,
                        setActiveTask,
                        activeTask,
                        reload,
                      })}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default withStyles(styles)(CustomTable);

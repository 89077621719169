import React from "react";

const Logo = props => (
  <svg
    width={190}
    height={30}
    style={{
      padding: "9px",
      backgroundColor: "rgb(0,0,0,0)"
    }}
    {...props}
  >
    <path d="M19.4 5.2H1.1C.4 5.2 0 6.5 0 7.8s.4 2.6 1.1 2.6h6.4V25c0 .7 1.3 1.1 2.7 1.1S13 25.8 13 25V10.5h6.4c.8 0 1.1-1.3 1.1-2.6s-.3-2.7-1.1-2.7zM21.5 15.6C21.5 9.8 26.3 5 32.4 5c6 0 10.9 4.7 10.9 10.6s-4.8 10.6-10.9 10.6-10.9-4.8-10.9-10.6zm10.9 5.3c3 0 5.5-2.4 5.5-5.3s-2.4-5.3-5.5-5.3-5.5 2.4-5.5 5.3 2.4 5.3 5.5 5.3zM78.1 14c0-5-4.2-9-9.3-9-2.6 0-4.9 1-6.6 2.7-1.7-1.6-4-2.7-6.6-2.7-5.1 0-9.3 4-9.3 9v11c0 .7 1.3 1.1 2.7 1.1 1.3 0 2.7-.4 2.7-1.1V14c0-2.1 1.7-3.8 3.9-3.8 2.1 0 3.9 1.7 3.9 3.8v11c0 .7 1.3 1.1 2.7 1.1 1.3 0 2.7-.4 2.7-1.1V14c0-2.1 1.7-3.8 3.9-3.8 2.1 0 3.9 1.7 3.9 3.8v11c0 .7 1.3 1.1 2.7 1.1 1.3 0 2.7-.4 2.7-1.1V14zM157.8 14c0-5-4.2-9-9.3-9-2.6 0-4.9 1-6.6 2.7-1.7-1.6-4-2.7-6.6-2.7-5.1 0-9.3 4-9.3 9v11c0 .7 1.3 1.1 2.7 1.1 1.3 0 2.7-.4 2.7-1.1V14c0-2.1 1.7-3.8 3.9-3.8 2.1 0 3.9 1.7 3.9 3.8v11c0 .7 1.3 1.1 2.7 1.1s2.7-.4 2.7-1.1V14c0-2.1 1.7-3.8 3.9-3.8 2.1 0 3.9 1.7 3.9 3.8v11c0 .7 1.3 1.1 2.7 1.1 1.3 0 2.7-.4 2.7-1.1V14zM99.2 5.2H80.9c-.8 0-1.1 1.3-1.1 2.6s.4 2.6 1.1 2.6h6.4V25c0 .7 1.3 1.1 2.7 1.1 1.3 0 2.7-.4 2.7-1.1V10.5h6.4c.8 0 1.1-1.3 1.1-2.6.1-1.4-.3-2.7-1-2.7zM101.3 15.6c0-5.8 4.8-10.6 10.9-10.6 6 0 10.9 4.7 10.9 10.6s-4.8 10.6-10.9 10.6c-6.1 0-10.9-4.8-10.9-10.6zm10.8 5.3c3 0 5.5-2.4 5.5-5.3s-2.4-5.3-5.5-5.3c-3 0-5.5 2.4-5.5 5.3.1 3 2.5 5.3 5.5 5.3z" />
    <g>
      <radialGradient
        id="prefix__a"
        cx={520.6}
        cy={-221.5}
        r={27}
        gradientTransform="matrix(.2138 .9781 1.0042 -.2083 291.7 -545.5)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset=".4" stopColor="#fdfdfd" stopOpacity=".7" />
        <stop offset=".5" stopColor="#f6f6f6" stopOpacity=".6" />
        <stop offset=".6" stopColor="#ebebeb" stopOpacity=".6" />
        <stop offset=".7" stopColor="#dadada" stopOpacity=".5" />
        <stop offset=".8" stopColor="#c4c4c4" stopOpacity=".5" />
        <stop offset=".8" stopColor="#a8a8a8" stopOpacity=".4" />
        <stop offset=".9" stopColor="#888" stopOpacity=".4" />
        <stop offset=".9" stopColor="#626262" stopOpacity=".4" />
        <stop offset="1" stopColor="#373737" stopOpacity=".3" />
        <stop offset="1" stopColor="#090909" stopOpacity=".3" />
        <stop offset="1" stopOpacity=".3" />
      </radialGradient>
      <path
        fill="url(#prefix__a)"
        d="M172.8 1.9c-7.4 1.5-12.2 8.7-10.6 15.9 1.6 7.2 8.9 11.8 16.3 10.3s12.2-8.7 10.6-15.9C187.6 5 180.3.3 172.8 1.9"
      />
      <g fill="#DF1B12">
        <path d="M179.3 20.9c-.4-.3-1.1-.4-1.7.1l-.1.1c-.9.8-2.6 2.3-3.8 2-.9-.2-1.1-1.1-1-1.9 0-.2 0-.4.1-.6 0-.1 0-.3.1-.5.1-.6 1.1-4.9 1.6-7.1.1-.3.1-.7.1-1-.1-.5-.4-.6-1-.8-.6-.1-1.5.1-1.7.8-.3.8-.5 1.9-1.2 5.2 0 .1-.1.3-.1.5-.1.4-.2.7-.7.6-.4-.1-.5-.6-.5-1v-.9c-.1-1.2-.1-2.6 0-4l.1-.8.1-1.4c0-.4 0-.6-.2-.8-.2-.1-.5-.2-.9-.1-.3.1-.6.2-.8.4-.2.2-.3.5-.3.8-.1.7-.1 1.5-.1 2.2-.1 1.5-.1 2.9 0 4.4v.3c0 .7-.1 1-.5 1s-.6-.4-.7-.7v-.2c-.2-.8-.3-1.7-.4-2.6l-.1-.5c-.1-.9-.3-1.4-.3-2.2 0-.1-.1-.4-.3-.5-.1 0-.3-.1-.6.1-.8.4-.7 1.3-.7 1.3 0 .7.1 1.4.1 2.1.2 1.5.4 2.5.6 3.7v.1c.1.4 0 .6-.1.7-.1.1-.2.1-.4 0-.3-.1-.5-.5-.6-.8-.4-.9-.7-1.9-1-2.9v-.1s-.1-.2-.2-.2-.3 0-.5.3c-.4.4-.1 1.5 0 2 .6 3 3.1 7.7 7.7 9.5 3.5 1.4 5.7-.4 10.1-4.2.4-.3.8-.8.9-1.3 0-.2 0-.4-.2-.7-.4-.1-.6-.3-.8-.4z" />
        <path d="M189.4 10.2c-1.3-3.9-3.7-5.7-4.1-6-.1-.1-.9-.7-1.3-.8-.1 0-.2 0-.4-.1h-.2c-.1 0-.1.1-.1.1 0 .1.2.4.5.7.7.6 1.6 1.6 1.9 2 .2.3.3.6 0 .8-.1 0-.2.1-.4 0-.1 0-.2-.1-.4-.3-.9-.9-3.2-2.8-4.6-3.7-.6-.4-1.2-.5-1.7-.4 0 0-.3.1-.4.3-.1.2.1.5.1.5.6.5 3.5 3 4 3.6.5.5.5.8.3 1-.3.3-.7 0-.8-.1-.1-.1-.2-.1-.3-.3-1.4-1.1-4.6-3.1-5.4-3.5-.8-.4-1.1-.5-1.9-.1 0 0-.5.3-.5.6s.1.5.4.7c3.1 2 3.8 2.4 6.3 4.2.2.1.6.5.2.9-.1.1-.3.1-.5.1-.3-.1-.5-.2-.7-.3-1.3-.8-3.7-2-5-2.4-.9-.3-1.4 0-1.7.4-.3.3-.3.7-.3 1 .1.3.4.5.6.7 1.7 1 3.1 1.6 4.8 2.6.5.3.6.4 1.2.7.6.4 1.5 1 1.8 1.7 0 .1.1.4.1.5 0 .3-.1.7-.6 1-.8.5-2.3.4-3.6.3h-1.1c-.7 0-1.1.2-1.3.5-.2.3-.2.8-.2 1v.3c0 .1.1.3.1.3.3.4.9.5 1.6.6 1.2.1 2.5.1 4 .1 3.5.1 7.4-.1 9-2.2 1.5-1.4 1.4-4.7.6-7z" />
      </g>
    </g>
  </svg>
);

export default Logo;

import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../ui/theme";

export const useCommentListStyles = makeStyles({
  newReply: {
    marginRight: "20px",
  },
  buttonSaveCancel: {
    display: "inline-block",
    marginRight: "5px",
    fontSize: "14px",
    textAlign: "center",
    marginTop: "3px",
    cursor: "pointer",
    border: "0px none",
    height: "32px",
    fontFamily: "Gotham Bold",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
    "&:enabled": {
      backgroundColor: colors.accent.lightBlue,
      color: colors.white,
      "&:disabled": {
        backgroundColor: colors.white,
        color: colors.white,
      },
    },
  },
  labelInInputReplies: {
    fontSize: "12px",
    fontWeight: "bold",
    color: colors.blacks.lightBlack,
  },
  buttonReply: {
    MozAppearance: "none",
    WebkitAppearance: "none",
    appearance: "none",
    fontSize: "14px",
    padding: "4px 8px",
    border: "none",
    background: "none",
    marginLeft: "50px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "none",
      transform: "scale(1.05)",
    },
    "&:focus": {
      cursor: "pointer",
      backgroundColor: "none",
    },
    "&:active": {
      cursor: "pointer",
      backgroundColor: "none",
    },
  },
  root: {
    width: "100%",
    maxWidth: "100%",
    margin: "auto",
    backgroundColor: "#fff",
    border: "1px solid transparent",
  },
  buttonStartConversation: {
    backgroundColor: colors.accent.darkBlue,
    width: "520px",
    marginLeft: "2px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.accent.brandBlue,
      cursor: "pointer",
      "&:enabled": {
        backgroundColor: colors.accent.brandBlue,
      },
    },
  },
  iconStartConversation: {
    color: "#fff",
    width: "20px",
  },
  buttonAddForThread: {
    width: "100%",
    fontWeight: "bold",
    borderRadius: "5px",
    backgroundColor: "#fff",
    color: colors.indigo.lightIndigo,
    border: `2px solid ${colors.accent.lightBlue}`,
    marginLeft: "50px",
    "&:hover": {
      backgroundColor: colors.accent.lightBlue,
      cursor: "pointer",
      "&:enabled": {
        backgroundColor: colors.accent.lightBlue,
      },
    },
  },
  icon: {
    color: "#fff",
  },
  commentBorderLink: {
    display: "block",
    height: "calc(100% - 10px)",
    borderRight: "1px solid transparent",
    marginBottom: "5px",
    backgroundClip: "padding-box",
    borderBottom: `1px solid ${colors.border}`,
    borderLeft: `2px solid ${colors.border}`,
    borderBottomLeftRadius: "70px",
    width: "520px",
    marginTop: "-15px",
  },
  secondary: {
    fontSize: "14px",
    color: colors.blacks.brandBlack,
  },
  li: {
    position: "relative",
    paddingLeft: "15px",
    padding: "5px",
    display: "flex",
    justifyContent: "space-between",
    border: `1px solid ${colors.border}`,
    borderRadius: "3px",
    width: "520px",
    background: colors.hover,
    "&:hover": {
      borderLeft: `5px solid ${colors.accent.brandBlue}`,
      backgroundColor: "#f4f5f7",
      paddingLeft: "10px",
    },
  },
  edited: {
    fontSize: "12px",
    color: colors.blacks.lightBlack,
  },
  blocker: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "12px",
  },
  commentContainer: {
    marginTop: "10px",
  },
  arrowButton: {
    fill: colors.blacks.lightBlack,
    "&:hover": {
      fill: colors.accent.lightBlue,
      cursor: "pointer",
      transform: "scale(1.1)",
    },
  },
  childrenExpansion: {
    display: "flex",
    width: "520px",
    borderBottom: `1px solid ${colors.border}`,
    borderRight: `1px solid ${colors.border}`,
  },
  highlight: {
    background: colors.accent.darkBlue,
    animation: "$background-fade 15s forwards",
  },
  "@keyframes background-fade": {
    "20%": {
      background: "rgba(188, 220, 245, 0.2)",
    },
    "100%": {
      background: "#fff",
    },
  },
  replyForm: {
    paddingBottom: "20px",
    marginTop: "15px",
  },
  validation: {
    color: colors.primary.brandRed,
    fontSize: "12px",
  },
  field: {
    paddingBottom: "10px",
  },
});

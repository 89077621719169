import React from "react";
import Linkify from "react-linkify";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.spacing(50),
    maxHeight: theme.spacing(10),
    overflow: "auto",
    cursor: "pointer",
  },
}));

export default ({ record }) => {
  const classes = useStyles();
  if (!record || !record.description || !record.description.description)
    return null;
  return (
    <Linkify>
      <Typography className={classes.root}>
        {record.description.description}
      </Typography>
    </Linkify>
  );
};

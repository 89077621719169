export const GET_COMMENTS = "GET_COMMENTS";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const CLEAN_COMMENTS = "CLEAN_COMMENTS";
export const SET_ACTIVE_THREAD = "SET_ACTIVE_THREAD";
export const GET_FILTERED_COMMENTS = "GET_FILTERED_COMMENTS";

export const getComments = (id, data) => {
  return {
    type: GET_COMMENTS,
    payload: {
      id,
      data,
    },
  };
};

export const getFilteredComments = (id, data) => {
  return {
    type: GET_FILTERED_COMMENTS,
    payload: {
      id,
      data,
    },
  };
};

export const setActiveThread = (data) => {
  return {
    type: SET_ACTIVE_THREAD,
    payload: {
      data,
    },
  };
};

export const removeComment = (id) => {
  return {
    type: REMOVE_COMMENT,
    payload: {
      id,
    },
  };
};
export const updateCommentBody = (id, data) => {
  return {
    type: UPDATE_COMMENT,
    payload: {
      id,
      data,
    },
  };
};
export const cleanComments = () => {
  return {
    type: CLEAN_COMMENTS,
  };
};

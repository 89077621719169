import React, { useEffect } from "react";
import {
  Avatar,
  Badge,
  ClickAwayListener,
  Divider,
  Grow,
  LinearProgress,
  List,
  ListItem,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import Tooltip from "tombac-ui/dist/components/Tooltip/Tooltip";
import { fetchUtils } from "react-admin";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { format } from "timeago.js";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_COUNT,
} from "../actions/notificationsAction";

const useStyles = makeStyles((theme) => ({
  paper: {
    transformOrigin: "top right",
  },
  avatar: {
    float: "left",
  },
  header: {
    height: theme.spacing(5),
    padding: theme.spacing(1),
    width: theme.spacing(70),
  },
  caption: {
    display: "flex",
    justifyContent: "center",
    paddingRight: "45px",
    paddingTop: "5px",
  },
  bellIcon: {
    animation: "jingle 1s cubic-bezier(.36,.07,.19,.97) both",
    animationIterationCount: "infinite",
  },
  arrow: {
    width: "15px",
    position: "absolute",
    height: "15px",
    transform: "rotate(45deg)",
    top: "-8px",
    overflow: "hidden",
    borderLeft: "1px solid rgba(0,0,0,0.05)",
    borderTop: "1px solid rgba(0,0,0,0.05)",
    right: "16px",
    background: "white",
    zIndex: "10",
  },
  list: {
    maxHeight: theme.spacing(50),
    overflow: "auto",
  },
  listItem: {
    display: "flex",
    flexDirection: "column",
    minWidth: theme.spacing(60),
    maxWidth: theme.spacing(90),
  },
  loading: {
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  empty: {
    padding: theme.spacing(2),
  },
  read: {
    color: theme.palette.info.contrastText,
  },
  icon: {
    color: "#000",
  },
}));

const sleep = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function Notifications() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [lastPage, setLastPage] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.notifications);
  const messagesCount = useSelector((state) => state.notificationsCount);
  let closeToolTip;

  const handleScroll = (e) => {
    const element = e.target;
    if (element.scrollHeight - element.scrollTop < element.clientHeight + 10) {
      loadMessagesPage(lastPage + 1);
    }
  };

  const loadMessagesPage = async (page) => {
    if (page === lastPage) {
      return; // already loaded
    }
    setLastPage(page);
    const options = {};
    if (!options.headers) {
      options.headers = new Headers({ Accept: "application/json" });
    }
    const token = localStorage.getItem("token");
    if (!token) return;
    options.headers.set("Authorization", `Bearer ${token}`);
    const pagedMessages = await fetchUtils.fetchJson(
      "/notifications?perPage=5&page=" + page,
      options
    );
    if (pagedMessages.json && pagedMessages.json.length > 0) {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: [...messages, ...pagedMessages.json],
      });
    }
  };

  const closePopup = async () => {
    updateMessagesCount();
    setOpen(false);
    (async () => {
      setLastPage(0);
      await sleep(150);
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: [],
      });
    })();
  };

  const handleToggle = async () => {
    if (open) {
      closePopup();
      return;
    }
    setOpen(true);
    if (closeToolTip) closeToolTip();
    setLoading(true);
    await loadMessagesPage(1);
    setLoading(false);
  };

  const updateMessagesCount = () => {
    let active = true;
    (async () => {
      let messagesCount;
      try {
        const options = {};
        if (!options.headers) {
          options.headers = new Headers({ Accept: "application/json" });
        }
        const token = localStorage.getItem("token");
        if (!token) return;
        options.headers.set("Authorization", `Bearer ${token}`);
        messagesCount = await fetchUtils.fetchJson(
          "/notifications/new",
          options
        );
      } catch (err) {
        // swallow the exceptions, e.g. rate limit
      }
      if (active && messagesCount && messagesCount.json) {
        dispatch({
          type: GET_NOTIFICATIONS_COUNT,
          payload: messagesCount.json || {},
        });
      }
    })();
    return () => {
      active = false;
    };
  };

  const updateNotificationsCount = () => {
    const withSleep = async () => {
      await sleep(500); // soften the pressure on the main thead
      window.addEventListener("focus", closePopup);
      updateMessagesCount();
    };
    withSleep();
  };

  useEffect(updateNotificationsCount, []);

  return (
    <React.Fragment>
      <Tooltip content={() => "Notifications"} placement="left">
        {({ ref, open, close }) => {
          ref(anchorRef.current);
          closeToolTip = close;
          return (
            <IconButton
              color="inherit"
              ref={anchorRef}
              onMouseEnter={open}
              onMouseLeave={close}
              onClick={handleToggle}
              data-ga-event-category="AppBar"
              data-ga-event-action="toggleNotifications"
            >
              <Badge
                color="secondary"
                badgeContent={messagesCount ? messagesCount.total : 0}
              >
                {messagesCount && messagesCount.critical > 0 ? (
                  <NotificationsActiveIcon color="primary" />
                ) : (
                  <NotificationsIcon className={classes.icon} />
                )}
              </Badge>
            </IconButton>
          );
        }}
      </Tooltip>
      <Popper
        id="notifications-popup"
        anchorEl={anchorRef.current}
        open={open}
        placement="bottom-end"
        transition
        disablePortal
        role={undefined}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={closePopup}>
            <Grow in={open} {...TransitionProps}>
              <Paper
                className={classes.paper}
                elevation={3}
                onScroll={handleScroll}
              >
                <div className={classes.header}>
                  <div className={`.MuiPaper-elevation3 ${classes.arrow}`} />
                  <Avatar className={classes.avatar}>
                    <NotificationsActiveIcon className={classes.bellIcon} />
                  </Avatar>
                  <Typography className={classes.caption} variant="h6">
                    Notifications
                  </Typography>
                </div>
                <Divider />
                {loading ? (
                  <div className={classes.loading}>
                    <LinearProgress size={32} />
                  </div>
                ) : (
                  <List className={classes.list}>
                    {messages && messages.length ? (
                      messages.map((message, index) => (
                        <React.Fragment key={message.id}>
                          <ListItem
                            button
                            component="a"
                            onClick={closePopup}
                            href={message.link}
                            divider
                            alignItems="flex-start"
                            className={`${classes.listItem} ${
                              message.critical ? classes.critical : ""
                            } ${message.read ? classes.read : ""}`}
                          >
                            <Typography
                              gutterBottom
                              variant="subtitle1"
                              color={message.critical ? "secondary" : ""}
                            >
                              {message.title}
                            </Typography>
                            <Typography gutterBottom variant="subtitle2">
                              <span
                                id="notification-message"
                                dangerouslySetInnerHTML={{
                                  __html: message.body,
                                }}
                              />
                            </Typography>
                            {message.createdAt && (
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {format(new Date(message.createdAt))}
                              </Typography>
                            )}
                          </ListItem>
                        </React.Fragment>
                      ))
                    ) : (
                      <Typography
                        className={classes.empty}
                        color="textSecondary"
                      >
                        There are no notifications yet
                      </Typography>
                    )}
                  </List>
                )}
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </React.Fragment>
  );
}

import React, { useState } from "react";
import { colors } from "../../ui/theme";
import InputAdornment from "@material-ui/core/InputAdornment";
import Lock from "@material-ui/icons/Lock";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import { useInput } from "react-admin";

const PasswordInput = ({ classes, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const {
    input: { onChange },
    meta: { touched, error },
  } = useInput(props);

  return (
    <TextField
      variant="outlined"
      margin="normal"
      required
      name="password"
      label="Password"
      placeholder="Password"
      type={showPassword ? "text" : "password"}
      data-cy="password"
      autoComplete="current-password"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Lock size={30} style={{ fill: colors.blacks.lightBlack }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={!!(touched && error)}
      helperText={touched && error}
      onChange={onChange}
      className={classes.input}
      {...props}
    />
  );
};

export default PasswordInput;

export const GET_NOTIFICATIONS_COUNT = "GET_NOTIFICATIONS_COUNT";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

export const getNotificationsCount = (data) => {
  return {
    type: GET_NOTIFICATIONS_COUNT,
    payload: data,
  };
};

export const getNotifications = (data) => {
  return {
    type: GET_NOTIFICATIONS,
    payload: data,
  };
};

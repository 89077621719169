import React, { useEffect, useState, useCallback } from "react";
import { push } from "connected-react-router";
import { connect, useSelector } from "react-redux";
import Paper from "@material-ui/core/Paper";
import EnhancedTable from "../../components/table";
import TasksEmptyState from "../../components/emptyState";
import TasksHeader from "../../components/tasksHeader";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { useCurrentWorkPeriod } from "../common/currentPeriod";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getDecodedToken } from "../../security/decodeToken";
import { useDataProvider, Error } from "react-admin";
import { tasksListColumns } from "./listColumns";
import * as qs from "query-string";
import Details from "../../components/activityStreams/details";

const styles = createStyles({
  tableWrapper: {
    maxHeight: "100%",
    overflow: "auto",
  },
});

const StyledTable = withStyles(styles)(EnhancedTable);

const useStyles = makeStyles({
  paper: {
    padding: "0px 30px",
    backgroundColor: "inherit",
  },
  title: {
    marginRight: "auto",
    fontSize: "30px",
    marginBottom: "5px",
  },
  header: {
    marginBottom: "30px",
  },
});

const Tasks = ({ defaultWorkPeriod, workPeriodsData, basePath, ...props }) => {
  const dataProvider = useDataProvider();
  const [tasks, setTasks] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [activeTask, setActiveTask] = useState("");
  const [reload, setReload] = useState(Date.now());

  const classes = useStyles();
  useEffect(() => {
    const decodedToken = getDecodedToken();
    const userId = decodedToken.UserId;
    dataProvider
      .getList("tasks", {
        userId,
        workPeriodId: defaultWorkPeriod.id,
        pagination: { page: 1, perPage: 100 },
        sort: { field: "startDate", order: "DESC" },
      })
      .then(({ data }) => {
        setTasks(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, defaultWorkPeriod, reload]);

  const handleFocus = useCallback(() => {
    setReload(Date.now());
  }, []);

  useEffect(() => {
    window.addEventListener("focus", handleFocus);
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [handleFocus]);

  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return <Error error={error} errorInfo="Failed to load tasks" />;
  }

  return (
    <Paper className={classes.paper}>
      {defaultWorkPeriod && (
        <TasksHeader
          title="Assigned tasks"
          currentWorkPeriod={defaultWorkPeriod}
          {...props}
        />
      )}
      {tasks && tasks.length > 0 ? (
        <React.Fragment>
          <StyledTable
            records={tasks}
            columns={tasksListColumns}
            basePath={basePath}
            activeTask={activeTask}
            setActiveTask={setActiveTask}
            reload={reload}
            {...props}
          />
        </React.Fragment>
      ) : (
        <TasksEmptyState />
      )}
    </Paper>
  );
};

const ConnectedTasks = connect(undefined, { push })(Tasks);

const TasksList = (props) => {
  const [taskId, setTaskId] = useState(undefined);
  const {
    currentWorkPeriod,
    workPeriodsData,
    loading,
  } = useCurrentWorkPeriod();
  useEffect(() => {
    const params = qs.parse(props.location.search);
    if (params.selectedTaskId) {
      setTaskId(params.selectedTaskId);
    } else {
      setTaskId(undefined);
    }
  }, [props.location]);
  const selectedTask = useSelector(
    (state) => state.admin.resources.tasks.data[taskId] || {}
  );
  if (loading) {
    return <CircularProgress />;
  }
  return (
    <>
      <ConnectedTasks
        defaultWorkPeriod={currentWorkPeriod}
        workPeriodsData={workPeriodsData}
        {...props}
      />
      {!!taskId &&
      selectedTask.constructor === Object &&
      Object.keys(selectedTask).length > 0 ? (
        <Details
          open={!!taskId}
          selectedTask={selectedTask}
          selectedTaskId={taskId}
          {...props}
        />
      ) : null}
    </>
  );
};
export default connect(undefined, { push })(TasksList);

import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { userLogout } from "react-admin";
import { connect } from "react-redux";
import { colors } from "../../ui/theme";
import ChevronDownIcon from "tombac-ui/dist/components/Icon/ChevronDownIcon";
import { getDecodedToken } from "../../security/decodeToken";
import { Menu, StyledMenuItem } from "../../components/menu";

const styles = () =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      padding: "24px",
      fontFamily: "Gotham",
      fontSize: "12px",
      fontWeight: "500",
      cursor: "pointer",
      position: "relative",
    },
    userEmail: {
      marginTop: "4px",
      fontFamily: "Noway",
      fontSize: "12px",
      fontWeight: "normal",
      color: colors.blacks.lightBlack,
    },
    header: {
      fontWeight: 600,
      color: "#000",
    },
    chevron: {
      marginLeft: "8px",
      marginRight: "-8px",
    },
    logoutText: {
      color: colors.primary.brandRed,
    },
  });

const getUserName = () => {
  let token = localStorage.getItem("token");
  const decodedToken = getDecodedToken(token);
  if (
    decodedToken.Firstname !== undefined &&
    decodedToken.Lastname !== undefined
  ) {
    return `${decodedToken.Firstname} ${decodedToken.Lastname}`;
  }
  return decodedToken.Username;
};

class CustomMenu extends React.Component {
  state = { isDropdownOpen: false };
  handleClick = () => {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
  };

  handleClose = () => {
    this.setState({ isDropdownOpen: false });
  };

  render() {
    const { isDropdownOpen } = this.state;
    const { userLogout, classes } = this.props;
    return (
      <React.Fragment>
        <div
          className={classes.container}
          onClick={this.handleClick}
          data-cy="logged-in-box"
        >
          <div>
            <div className={classes.header}>Logged in as</div>
            <div className={classes.userEmail} data-hj-suppress="">
              {getUserName()}
            </div>
          </div>
          <div className={classes.chevron} onClick={this.handleClick}>
            <ChevronDownIcon width={24} height={24} color="#000" />
          </div>
        </div>
        {isDropdownOpen && (
          <Menu>
            <StyledMenuItem
              className={classes.logoutText}
              onClick={userLogout}
              onMouseLeave={this.handleClose}
              data-cy="logged-in-box-logout-btn"
            >
              Logout
            </StyledMenuItem>
          </Menu>
        )}
      </React.Fragment>
    );
  }
}
const redirect = "/login";
const customUserLogout = () => userLogout(redirect);

const ConnectedCustomMenu = connect(undefined, {
  userLogout: customUserLogout,
})(CustomMenu);

export default withStyles(styles)(ConnectedCustomMenu);

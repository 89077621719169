import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

export const colors = {
  primary: {
    lightRed: "#e94743",
    darkRed: "#b1110e",
    brandRed: "#df1b12",
  },
  accent: {
    lightBlue: "#bcdcf5",
    darkBlue: "#61ade0",
    brandBlue: "#8dc3eb",
    labelBlue: "#78a",
  },
  blacks: {
    lightBlack: "#7a7e80",
    darkBlack: "#4a4c4c",
    brandBlack: "rgba(0, 0, 0, 0.87)",
    shadowBlack: "#f2f2f2",
  },
  teals: {
    lightTeal: "#65a7a9",
    teal: "#008d8d",
    darkTeal: "#006967",
  },
  cadmiums: {
    lightCadmium: "#ffdc7a",
    cadmium: "#fdc530",
    darkCadmium: "#f9b023",
  },
  indigo: {
    lightIndigo: "#5d7ca8",
    brandIndigo: "#004b7f",
    darkIndigo: "#002c5e",
  },
  pink: {
    lightPink: "#f8c9df",
    brandPink: "#f29ac2",
    darkPink: "#ec619f",
  },
  white: "#ffffff",
  border: "#e5e5e5",
  background: "#fbfbfb",
  hover: "#f2f6f8",
  layoutBackgroundColor: "#f2f2f2",
  cardBackgroundColor: "#fff",
};

export const TomTomTheme = createMuiTheme({
  typography: {
    fontFamily: "Gotham,Helvetica,Arial,sans-serif",
    fontWeight: 400,
    color: colors.blacks.brandBlack,
  },
  palette: {
    primary: {
      light: colors.accent.lightBlue,
      main: colors.accent.brandBlue,
      dark: colors.accent.darkBlue,
      contrastText: colors.white,
    },
    secondary: {
      light: colors.primary.lightRed,
      main: colors.primary.brandRed,
      dark: colors.primary.darkRed,
      contrastText: colors.white,
    },
    info: {
      light: colors.cadmiums.lightCadmium,
      main: colors.cadmiums.cadmium,
      dark: colors.cadmiums.darkCadmium,
      contrastText: colors.blacks.lightBlack,
    },
    error: {
      light: colors.primary.lightRed,
      main: colors.primary.brandRed,
      dark: colors.primary.darkRed,
      contrastText: colors.white,
    },
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
    },
    MuiTypography: {
      h2: {
        fontFamily: "Gotham Bold, Helvetica, Arial, sans-serif",
        fontSize: "18px",
      },
      h3: {
        fontFamily: "Gotham Bold, Helvetica, Arial, sans-serif",
        fontSize: "14px",
        height: "15px",
        margin: "10px 0",
        padding: 0,
      },
      h4: {
        fontFamily: "Gotham, Helvetica, Arial, sans-serif",
        fontSize: "12px",
        margin: "10px 0",
        fontWeight: "500",
        padding: 0,
        color: "#666",
      },
      body1: {
        fontSize: "14px",
        fontWeight: 300,
      },
      body2: {
        fontFamily: "Gotham, Helvetica, Arial, sans-serif",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.42",
        letterSpacing: "normal",
      },
    },
    MuiTable: {
      root: {
        border: "1px solid #e5e5e5",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
        fontSize: "14px",
        lineHeight: "0.5",
      },
      body: {
        fontFamily: "Noway, Helvetica,Arial,sans-serif",
        fontSize: "14px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: colors.blacks.brandBlack,
      },
      head: {
        fontFamily: "Gotham Bold, Noway, Helvetica,Arial,sans-serif",
        fontSize: "12px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.14",
        letterSpacing: "0.5px",
        color: colors.blacks.brandBlack,
        textTransform: "uppercase",
      },
      paddingNone: {
        paddingLeft: "12px",
      },
    },
    MuiTableRow: {
      root: {
        border: `1px solid ${colors.border}`,
        "&$hover:hover": {
          backgroundColor: colors.hover,
        },
        "&$selected, &$selected:hover": {
          backgroundColor: colors.white,
        },
      },
    },
    MuiCircularProgress: {
      root: {
        position: "absolute",
        top: "50vh",
        left: "50vw",
      },
    },
    MuiIconButton: {
      edgeEnd: {
        marginRight: "0px",
      },
    },
    MuiSnackbar: {
      root: {
        zIndex: "var(--z-index-highest)",
      },
    },
  },
});

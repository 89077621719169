import React from "react";
import { withStyles } from "@material-ui/core";
import { colors } from "../ui/theme";
import MenuItem from "@material-ui/core/MenuItem";

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    display: "block",
    padding: "12px 40px",
    fontFamily: "Gotham",
    fontSize: "12px",
    fontWeight: "600",
    color: colors.blacks.brandBlack,
    "&:focus": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export const Menu = withStyles((theme) => ({
  root: {
    position: "absolute",
    width: "200px",
    top: "70px",
    right: 0,
    background: colors.white,
    boxShadow: "0 8px 40px 4px rgba(27, 43, 54, 0.15)",
  },
}))(({ children, classes }) => {
  return (
    <div className={classes.root} data-cy="logged-in-box-dropdown">
      {children}
    </div>
  );
});

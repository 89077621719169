import React from "react";
import { TextField, ReferenceField, ArrayField } from "react-admin";
import { ActivityStreamNameAndBlockerFlag } from "../../components/blockerFlag/blockerFlag";
import Description from "../../components/activityStreams/description";
import LinkField from "../../components/linkField";
import Link from "@material-ui/core/Link";
import OrderedListField from "../../components/orderedListField";
import InstructionLink from "../../components/activityStreams/instructionLink";
import AutoServeSwitch from "./autoserve/switch";
const columns = [
  {
    id: "activityStreamId",
    label: "Activity Stream / Non project activity",
    minWidth: 200,
    component: function ({ record, basePath }) {
      if (record && record.activityStreamId) {
        return (
          <ReferenceField
            record={record}
            basePath={basePath}
            source="activityStreamId"
            reference="activityStreams"
            link={false}
          >
            <ActivityStreamNameAndBlockerFlag />
          </ReferenceField>
        );
      }
      if (record && record.nonProjectActivityId) {
        return (
          <ReferenceField
            record={record}
            basePath={basePath}
            source="nonProjectActivityId"
            reference="nonProjectActivities"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
        );
      }
    },
  },
  {
    id: "workPackageId",
    label: "Project name",
    minWidth: 100,
    component: ({ record, basePath }) => (
      <ReferenceField
        record={record}
        basePath={basePath}
        source="workPackageId"
        reference="workPackages"
        link={false}
      >
        <TextField source="projectId" />
      </ReferenceField>
    ),
  },
  {
    id: "assignedMinutes",
    label: "Assigned hours",
    minWidth: 100,
    component: ({ record }) => Math.round(record["assignedMinutes"] / 6) / 10,
  },
];

const extraColumns = [
  {
    id: "toolLink",
    label: "Tool",
    minWidth: 250,
    component: ({ record, basePath }) => (
      <ReferenceField
        record={record}
        basePath={basePath}
        source="activityStreamId"
        reference="activityStreams"
        link={false}
      >
        <LinkField name="description.toolLink" />
      </ReferenceField>
    ),
  },
  {
    id: "activityDescription",
    label: "Description",
    minWidth: 200,
    component: ({ record, basePath }) => (
      <ReferenceField
        record={record}
        basePath={basePath}
        source="activityStreamId"
        reference="activityStreams"
        link={false}
      >
        <Description />
      </ReferenceField>
    ),
  },
  {
    id: "instructions",
    label: "Instructions",
    minWidth: 250,
    component: ({ record, basePath }) => (
      <ReferenceField
        record={record}
        basePath={basePath}
        source="activityStreamId"
        reference="activityStreams"
        link={false}
      >
        <ArrayField source="description.instructions">
          <OrderedListField
            source="name"
            name="instructions"
            listItemExtension={<InstructionLink />}
          />
        </ArrayField>
      </ReferenceField>
    ),
  },
  {
    id: "autoServe",
    label: "Auto-Claim",
    minWidth: 250,
    component: ({ record, setActiveTask, activeTask, reload }) => (
      <AutoServeSwitch
        activityStreamId={record.activityStreamId}
        setActiveTask={setActiveTask}
        activeTask={activeTask}
        reload={reload}
      />
    ),
  },
  {
    id: "details",
    label: "Actions",
    minWidth: 250,
    component: ({ record }) => {
      if (record.activityStreamId) {
        return (
          <Link href={`/#/tasks?selectedTaskId=${record.id}`}>See details</Link>
        );
      }
      return null;
    },
  },
];
const tasksListColumns = [...columns, ...extraColumns];
const tasksHistoryColumns = [
  ...columns,
  {
    id: "details",
    label: "Actions",
    minWidth: 250,
    component: ({ record, workPeriodId }) => {
      if (record.activityStreamId) {
        return (
          <Link
            href={`/#/tasksHistory?selectedTaskId=${record.id}&workPeriodId=${workPeriodId}`}
          >
            See details
          </Link>
        );
      }
      return null;
    },
  },
];

export { tasksListColumns, tasksHistoryColumns };

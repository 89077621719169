import React, { useEffect, useState } from "react";
import { useStyles as useIconButtonStyles } from "../../ui/iconButtonStyles";
import { useDataProvider, useNotify, Error } from "react-admin";
import EditIcon from "tombac-ui/dist/components/Icon/EditIcon";
import DeleteIcon from "tombac-ui/dist/components/Icon/DeleteIcon";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import ReplyCreate from "./reply";
import { removeComment, updateCommentBody } from "../../actions/commentsAction";
import { NONE } from "../blockerFlag";

const useEditModeStyles = makeStyles({
  editMode: {
    position: "absolute",
    right: 0,
    display: "inline",
    verticalAlign: "middle",
  },
  icons: {
    padding: "5px 0",
  },
});

export const CommentEditForm = (props) => {
  const {
    activityStreamId,
    commentId,
    setEditFormVisible,
    editFormVisible,
  } = props;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const dispatch = useDispatch();
  const [comment, setComment] = useState();
  const [isFetching, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider
      .getOne("commentsEdit", { activityStreamId, commentId })
      .then(({ data }) => {
        setComment(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, commentId, activityStreamId]);
  const handleUpdateComment = ({ body }) => {
    dataProvider
      .update("commentsEdit", {
        activityStreamId,
        commentId,
        data: { body: body },
      })
      .then(({ data }) => {
        notify("Comment updated", "info", {}, false);
        setEditFormVisible(false);
        dispatch(updateCommentBody(commentId, data));
        setEditFormVisible(!editFormVisible);
      })
      .catch((error) => {
        notify("Couldn't update comment");
        console.log(error);
        setEditFormVisible(!editFormVisible);
      });
  };
  if (isFetching) return <CircularProgress />;
  if (error) return <Error error={error} errorInfo="Failed fetch comment" />;
  if (!comment) return null;
  return (
    <div style={{ width: "520px" }}>
      <ReplyCreate
        {...props}
        onCancel={() => setEditFormVisible(!editFormVisible)}
        handleFormSubmit={handleUpdateComment}
      />
    </div>
  );
};

export const EditActions = ({
  setEditFormVisible,
  commentId,
  activityStreamId,
  isBlockerComment,
}) => {
  const classes = useEditModeStyles();
  const dataProvider = useDataProvider();
  const iconButtonClasses = useIconButtonStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const handleDelete = (e) => {
    e.preventDefault();
    dataProvider
      .delete("commentsEdit", { activityStreamId, commentId })
      .then(({ data }) => {
        dispatch(removeComment(commentId));
        notify("Comment deleted", "info", {}, false);
        setLoading(false);
      })
      .catch((error) => {
        notify(
          error && error.message ? error.message : "Couldn't delete comment"
        );
        setLoading(false);
      });
  };
  return (
    <div className={classes.editMode}>
      <button
        onClick={() => {
          setEditFormVisible({ [commentId]: true });
        }}
        className={classnames(iconButtonClasses.iconButton, classes.icons)}
      >
        <EditIcon color="#000" width={27} height={27} />
      </button>
      {isBlockerComment === NONE && (
        <button
          className={classnames(iconButtonClasses.iconButton, classes.icons)}
          onClick={(event) => {
            handleDelete(event);
          }}
          disabled={loading}
        >
          <DeleteIcon color="#000" width={24} height={24} />
        </button>
      )}
    </div>
  );
};

import React, { useState, useEffect, useRef } from "react";
import {
  useDataProvider,
  Error,
  ReferenceField,
  TextField,
  ArrayField,
  useNotify,
} from "react-admin";
import Linkify from "react-linkify";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer/Drawer";
import Link from "@material-ui/core/Link";
import ExpansionPanel from "@material-ui/core/Accordion";
import ExpansionPanelDetails from "@material-ui/core/AccordionDetails";
import ExpansionPanelSummary from "@material-ui/core/AccordionSummary";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CommentsList from "../comments/list";
import OrderedListField from "../../components/orderedListField";
import { colors } from "../../ui/theme";
import { connect, useDispatch } from "react-redux";
import {
  getComments,
  cleanComments,
  setActiveThread,
  getFilteredComments,
} from "../../actions/commentsAction";
import { push } from "connected-react-router";
import Tooltip from "tombac-ui/dist/components/Tooltip/Tooltip";
import LinkIcon from "@material-ui/icons/Link";
import * as qs from "query-string";
import ActivityStreamToolbar from "./toolbar";
import Input from "tombac-ui/dist/components/Input/Input";
import SearchIcon from "tombac-ui/dist/components/Icon/SearchIcon";
import CancelIcon from "tombac-ui/dist/components/Icon/CancelIcon";
import { httpClient } from "../../rest";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.background,
  },
  drawer: {
    backgroundColor: colors.background,
    zIndex: "2",
    margin: "5% 25%",
    height: "85%",
    flexDirection: "column",
  },
  container: {
    padding: "16px 32px",
    width: theme.spacing(100),
  },

  detailContainer: {
    margin: "16px 0",
  },
  expansionPanel: {
    backgroundColor: colors.background,
  },
  expandSummary: {
    paddingLeft: "0",
  },
  expandIcon: {
    order: -1,
  },
  expandDetails: {
    flexDirection: "column",
  },
  inputContainer: {
    position: "relative",
  },
  input: {
    margin: "1em 0",
    width: "520px",
    border: ` 1px solid ${colors.accent.darkBlue}`,
  },
  clearBtn: {
    position: "absolute",
    display: "inline-block",
    left: "490px",
    top: "20px",
    cursor: "pointer",
  },
}));
const Details = ({ open, comments, selectedTask, ...props }) => {
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const notify = useNotify();
  const classes = useStyles();
  const [refreshASDetails, setRefreshAsDetails] = useState(false);
  const [asDetails, setASDetails] = useState(false);
  const [commentsLastPage, setCommentsLastPage] = useState(false);
  const [refreshComments, setRefreshComments] = useState(false);
  const [commentId, setCommentId] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [searchValue, setValue] = useState("");
  const [expanded, setExpanded] = useState({
    description: true,
    comments: true,
    instructions: true,
  });
  const commentsRef = useRef(null);
  const activityStreamId = selectedTask.activityStreamId;

  const getPagedComments = async (activityStreamId, page, searchValue) => {
    try {
      const { data } = await dataProvider.getList("comments", {
        activityStreamId: activityStreamId,
        pagination: { page: page, perPage: 25 },
        sort: { field: "createdAt", order: "DESC" },
        filter: {
          search:
            searchValue && searchValue.length > 0 ? searchValue : undefined,
        },
      });
      //don't ask for a next page if last requested is an empty array
      if (data && data.length === 0) {
        setCommentsLastPage(false);
      }
      return data;
    } catch (e) {
      throw new Error(e);
    }
  };

  const handleScroll = (e) => {
    const element = e.target;
    if (
      element.scrollHeight - element.scrollTop < element.clientHeight + 10 &&
      commentsLastPage
    ) {
      loadASComments(commentsLastPage + 1, activityStreamId);
    }
  };
  const loadASComments = async (
    page,
    activityStreamId,
    id = undefined,
    thread = undefined
  ) => {
    setCommentsLastPage(page);
    getPagedComments(activityStreamId, page, searchValue)
      .then((data) => {
        dispatch(getComments(activityStreamId, data));
        // (redirect to specified comment from notification)
        if (id) {
          const element = data.find((el) => {
            if (thread && thread.id) {
              return el.id === thread.id;
            }
            return false;
          });
          if (element) {
            setCommentId(id);
          } else {
            if (page !== 6) {
              loadASComments(page + 1, activityStreamId, id, thread);
            } else {
              notify("Couldn't find comment");
            }
          }
        } else {
          setCommentId(undefined);
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const loadAS = (activityStreamId) => {
    dataProvider
      .getOne("activityStreams", { id: activityStreamId })
      .then(({ data }) => {
        setASDetails(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (activityStreamId) {
      setValue("");
      loadAS(activityStreamId);
    }
  }, [dataProvider, open, refreshASDetails, activityStreamId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activityStreamId) {
      const { commentId } = qs.parse(props.location.search);
      dispatch(cleanComments([]));
      if (commentId) {
        getCommentsChildren(activityStreamId, commentId)
          .then((thread) => {
            dispatch(setActiveThread(thread));
            loadASComments(1, activityStreamId, commentId, thread);
          })
          .catch((e) => {
            if (e.responseCode === 404) {
              notify(e.message);
            } else {
              setError(e);
              console.log(e);
            }
          });
        return;
      }
      loadASComments(1, activityStreamId);
    }
  }, [refreshComments, props.location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => dispatch(cleanComments([]));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const handleClose = props.redirect
    ? props.redirect
    : () => props.push(props.basePath);

  const handleExpand = (key) =>
    setExpanded({ ...expanded, [key]: !expanded[key] });

  const handleFilteringComments = _.debounce((searchValue) => {
    if (searchValue && searchValue.length > 2) {
      getPagedComments(activityStreamId, 1, searchValue)
        .then((response) => {
          dispatch(getFilteredComments(activityStreamId, response));
          setCommentsLastPage(1);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, 1000);
  const handleClear = () => {
    setValue("");
    setRefreshComments(!refreshComments);
  };
  if (loading) return <CircularProgress />;
  if (error) return <Error error={error} errorInfo={error.message} />;
  if (!asDetails) return null;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={
        selectedTask.workPackageId
          ? { className: classes.root }
          : { className: classes.drawer }
      }
      onScroll={handleScroll}
    >
      <ActivityStreamToolbar
        handleClose={handleClose}
        record={asDetails}
        refreshASDetails={refreshASDetails}
        setRefreshAsDetails={setRefreshAsDetails}
        refreshComments={refreshComments}
        setRefreshComments={setRefreshComments}
        {...props}
      />
      <div className={classes.container}>
        <div className={classes.detailContainer}>
          <Typography variant="h3">Activity stream name</Typography>
          <Typography variant="body2">{asDetails.name}</Typography>
        </div>
        <div className={classes.detailContainer}>
          <Typography variant="h3">Project name</Typography>
          <ReferenceField
            record={selectedTask}
            basePath={props.basePath}
            source="workPackageId"
            reference="workPackages"
            link={false}
          >
            <TextField source="projectId" />
          </ReferenceField>
        </div>
        <div className={classes.detailContainer}>
          <Typography variant="h3">Link to a tool</Typography>
          {asDetails.description &&
          asDetails.description.toolLink &&
          asDetails.description.toolLink.length > 0 ? (
            <Link
              href={asDetails.description.toolLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {asDetails.description.toolLink}
            </Link>
          ) : (
            <Typography variant="body2">There is no tool link</Typography>
          )}
        </div>
        <ExpansionPanel
          expanded={expanded.description}
          onChange={() => handleExpand("description")}
          className={classes.expansionPanel}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.expandSummary}
          >
            <Typography variant="h3">Description</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="body2">
              <Linkify>
                {asDetails.description &&
                asDetails.description.description &&
                asDetails.description.description.length > 0
                  ? asDetails.description.description
                  : "There is no description for this task"}
              </Linkify>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded.instructions}
          onChange={() => handleExpand("instructions")}
          className={classes.expansionPanel}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.expandSummary}
          >
            <Typography variant="h3">Instructions</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ReferenceField
              record={{ activityStreamId }}
              basePath={props.basePath}
              source="activityStreamId"
              reference="activityStreams"
              link={false}
            >
              <ArrayField source="description.instructions">
                <OrderedListField
                  {...props}
                  source="name"
                  name="instructions"
                  listItemExtension={<InstructionLink />}
                />
              </ArrayField>
            </ReferenceField>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded.comments}
          onChange={() => handleExpand("comments")}
          className={classes.expansionPanel}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.expandSummary}
            onScroll={handleScroll}
          >
            <Typography variant="h3">Comments</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            className={classes.expandDetails}
            ref={commentsRef}
          >
            <Typography variant="h4">Search these comments</Typography>
            <div className={classes.inputContainer}>
              <Input
                data-cy="searchInput"
                onChange={(e) => {
                  setValue(e.target.value);
                  e.preventDefault();
                  handleFilteringComments(searchValue);
                }}
                value={searchValue}
                placeholder="Search"
                icon={(iconProps) => <SearchIcon {...iconProps} />}
                className={classes.input}
              />
              <span className={classes.clearBtn} onClick={handleClear}>
                <CancelIcon color="#333" width={24} height={24} />
              </span>
            </div>
            <CommentsList
              {...props}
              activityStreamId={activityStreamId}
              refreshComments={refreshComments}
              setRefreshComments={setRefreshComments}
              redirectToCommentId={commentId}
              setCommentId={setCommentId}
              handleChange={handleFilteringComments}
              handleClear={handleClear}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </Drawer>
  );
};
export default connect(undefined, { push })(Details);

const InstructionLink = ({ data, id }) => {
  const useStyles = makeStyles({
    icon: {
      verticalAlign: "middle",
      marginLeft: "5px",
      fill: colors.blacks.brandBlack,
    },
  });
  const classes = useStyles();
  return (
    <Link target="_blank" rel="noopener noreferrer" href={data[id].link}>
      <Tooltip content={() => `${data[id].link}`}>
        {({ ref, open, close }) => (
          <LinkIcon
            ref={ref}
            onMouseEnter={open}
            onMouseLeave={close}
            className={classes.icon}
          />
        )}
      </Tooltip>
    </Link>
  );
};

export const getCommentsChildren = async (activityStreamId, commentId) => {
  try {
    const comments = await httpClient(
      `/activityStreams/${activityStreamId}/comments/${commentId}/children`,
      {
        method: "GET",
      }
    );
    if (comments.status === 200) {
      if (comments && comments.json) {
        return comments.json;
      }
    }
  } catch (e) {
    return e;
  }
};

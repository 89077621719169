import { UserAgentApplication, Logger, LogLevel } from "msal";

const requiresInteraction = (errorMessage) => {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }

  return (
    errorMessage.indexOf("consent_required") > -1 ||
    errorMessage.indexOf("interaction_required") > -1 ||
    errorMessage.indexOf("login_required") > -1
  );
};

const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ") > -1;
  const msie11 = ua.indexOf("Trident/") > -1;
  return msie || msie11;
};

export const msalApp = new UserAgentApplication({
  auth: {
    clientId: "593efd83-5efc-46c2-9536-fdc8165a7164",
    authority:
      "https://login.microsoftonline.com/TomTomInternational.onmicrosoft.com",
    validateAuthority: true,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE(),
  },
  system: {
    navigateFrameWait: 500,
    logger: new Logger(
      (logLevel, message) => {
        console.log(message);
      },
      { level: LogLevel.Error }
    ),
  },
});

msalApp.login = () => {
  const request = {
    scopes: ["openid", "profile", "User.Read"],
  };
  msalApp.loginRedirect(request);
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject("Something went wrong, please try again later");
    }, 1000);
  });
};

msalApp.silentLogin = (loginHint) => {
  const request = {
    scopes: ["openid", "profile", "User.Read"],
    loginHint: loginHint,
  };
  return msalApp.ssoSilent(request).catch((error) => {
    if (requiresInteraction(error.errorCode)) {
      return msalApp.loginRedirect(request);
    } else {
      console.error("Non-interactive error:", error.errorCode);
      return Promise.reject(error.errorMessage);
    }
  });
};

import React, { useState, useEffect } from "react";
import { useQueryWithStore } from "react-admin";
import { Error } from "react-admin";

function useCurrentWorkPeriod() {
  const [loading, setLoading] = useState(true);
  const [currentWorkPeriod, setCurrentWorkPeriod] = useState(null);
  const [currentBudgetCycle, setCurrentBudgetCycle] = useState(null);

  useEffect(() => {
    async function getPeriod() {
      if (!isFetching && !isRequesting && workPeriodsData) {
        const currentDate = new Date().setHours(0, 0, 0, 0);
        const currentPeriod = workPeriodsData.find((record) => {
          const startDate = new Date(record.startDate).setHours(0, 0, 0, 0);
          const endDate = new Date(record.endDate).setHours(0, 0, 0, 0);
          return currentDate >= startDate && currentDate <= endDate;
        });
        if (currentPeriod === undefined) {
          return workPeriodsData[0].id;
        }
        const currentBudgetCycle = budgetCycleData.find(
          (budgetCycle) => budgetCycle.id === currentPeriod.budgetCycleId
        );
        setCurrentWorkPeriod(currentPeriod);
        setCurrentBudgetCycle(currentBudgetCycle);
        setLoading(false);
      }
    }
    getPeriod();
  });

  const {
    data: budgetCycleData,
    loading: isRequesting,
    budgetCyclesError,
  } = useQueryWithStore({
    type: "getList",
    resource: "budgetCycles",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "startDate", order: "DESC" },
    },
  });
  const {
    data: workPeriodsData,
    loading: isFetching,
    workPeriodsError,
  } = useQueryWithStore({
    type: "getList",
    resource: "workPeriods",
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "startDate", order: "DESC" },
    },
  });
  if (workPeriodsError || budgetCyclesError) {
    return <Error />;
  }

  return {
    currentWorkPeriod,
    currentBudgetCycle,
    workPeriodsData,
    loading,
  };
}
export { useCurrentWorkPeriod };

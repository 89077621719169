import React from "react";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";

const useOrderedListFieldStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr 80px 100px",
    alignItems: "center",
    gridGap: "2px",
  },
}));
const OrderedListField = ({ ids, data, name, source, listItemExtension }) => {
  const classes = useOrderedListFieldStyles();
  if (ids.length === 0) {
    return <Typography variant="body2">There are no {name}</Typography>;
  }
  return (
    <ol>
      {ids.map((id) => (
        <>
          <li key={id}>
            <div className={classes.container}>
              {_.get(data[id], source)}
              {React.cloneElement(listItemExtension, { data, id })}
            </div>
          </li>
        </>
      ))}
    </ol>
  );
};
export default OrderedListField;

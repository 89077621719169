import { stringify } from "query-string";
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from "react-admin";
import { MD5 } from "object-hash";
import { isTokenExpired } from "../security/decodeToken";

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  if (isTokenExpired()) {
    window.location.href = "/";
    return Promise.reject("You've been logged out, please log in again");
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const paths = {
  tasks: "/workPeriods/:workPeriodId/usersPlan/:userId",
  workPeriodInfo: "/workPeriods/:workPeriodId/editorInfo",
  comments: "/activityStreams/:activityStreamId/comments",
  commentsEdit: "/activityStreams/:activityStreamId/comments/:commentId",
  watchers: "/watchers/:featureId/:userId",
  autoclaim: "classification/autoclaim/:activityStreamId",
  userActivity: "userActivity",
};

const getPath = (resource, params, defaultPathParam) => {
  if (!paths[resource]) {
    return resource + (defaultPathParam || "");
  }
  let regex = /:(\w+)/g;
  let path = paths[resource];

  return path.replace(regex, function (match, param) {
    const filter = params.filter && params.filter[param];
    const data = params.data && params.data[param];
    return filter || data || params[param] || ":" + param;
  });
};

const convertDataRequestToHTTP = (type, resource, params) => {
  let url = "";
  const options = {};
  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...fetchUtils.flattenObject(params.filter),
        sort: field,
        order: order,
        page: page,
        perPage: perPage,
      };
      url = getPath(resource, params) + `?${stringify(query)}`;
      break;
    }
    case GET_ONE:
      url = getPath(resource, params, `/${params.id}`);
      break;
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...fetchUtils.flattenObject(params.filter),
        [params.target]: params.id,
        sort: field,
        order: order,
        page: page,
        perPage: perPage,
      };
      url = getPath(resource, params) + `?${stringify(query)}`;
      break;
    }
    case UPDATE:
      url = getPath(resource, params, `/${params.id}`);
      options.method = "PUT";
      options.body = JSON.stringify(params.data);
      break;
    case CREATE:
      url = getPath(resource, params);
      options.method = "POST";
      options.body = JSON.stringify(params.data);
      break;
    case DELETE:
      url = getPath(resource, params, `/${params.id}`);
      options.method = "DELETE";
      break;
    case GET_MANY: {
      const query = {
        id: params.ids,
      };
      url = getPath(resource, params) + `?${stringify(query)}`;
      break;
    }
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
  return { url, options };
};

const convertHTTPResponse = (response, type, resource, params) => {
  const { headers, json } = response;
  switch (type) {
    case GET_LIST:
    case GET_MANY_REFERENCE:
      if (!headers.has("x-total-count")) {
        return {
          data: json.map((resource) => ({
            ...resource,
            id: resource.id ? resource.id : MD5(resource),
          })),
          total: json.length,
        };
      }
      return {
        data: json.map((resource) => ({
          ...resource,
          id: resource.id ? resource.id : MD5(resource),
        })),
        total: parseInt(headers.get("x-total-count").split("/").pop(), 10),
      };
    case UPDATE:
      return { data: { ...params.data, ...json } };
    case CREATE:
      return { data: { ...params.data, ...json } };
    case DELETE:
      return { data: { ...json, id: params.id } };
    default:
      return { data: json || params.data };
  }
};

export default (type, resource, params) => {
  if (type === UPDATE_MANY) {
    return Promise.all(
      params.ids.map((id) =>
        httpClient(getPath(resource, params, `/${id}`), {
          method: "PUT",
          body: JSON.stringify(params.data),
        })
      )
    ).then((responses) => ({
      data: responses.map((response) => response.json),
    }));
  }
  if (type === DELETE_MANY) {
    return Promise.all(
      params.ids.map((id) =>
        httpClient(getPath(resource, params, `/${id}`), {
          method: "DELETE",
        })
      )
    ).then((responses) => ({
      data: responses.map((response) => response.json),
    }));
  }
  if (type === GET_MANY) {
    return Promise.all(
      params.ids.map((id) =>
        httpClient(getPath(resource, params, `/${id}`), {
          method: "GET",
        })
      )
    ).then((responses) => ({
      data: responses.map((response) => response.json),
    }));
  }
  const { url, options } = convertDataRequestToHTTP(type, resource, params);
  return httpClient(url, options).then((response) =>
    convertHTTPResponse(response, type, resource, params)
  );
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../ui/theme";
import Typography from "@material-ui/core/Typography";

const useBlockerFlagStyles = makeStyles((theme) => ({
  span: {
    backgroundColor: colors.primary.lightRed,
    width: "min-content",
    display: "inline-flex",
    cursor: "pointer",
    margin: "8px",
  },
  status: {
    fontFamily: "Gotham Bold",
    color: colors.white,
    padding: "5px",
    fontSize: "12px",
  },
}));
const BlockerFlag = () => {
  const classes = useBlockerFlagStyles();
  return (
    <span className={classes.span}>
      <span className={classes.status}>Blocked</span>
    </span>
  );
};
const useStyles = makeStyles((theme) => ({
  div: {
    display: "flex",
    alignItems: "center",
  },
}));
const ActivityStreamNameAndBlockerFlag = ({ record }) => {
  const classes = useStyles();
  return (
    <div className={classes.div}>
      <Typography>{record.name}</Typography>
      {record.blocked && <BlockerFlag />}
    </div>
  );
};
export { BlockerFlag, ActivityStreamNameAndBlockerFlag };

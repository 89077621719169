import React from "react";
import { Form, Formik, Field } from "formik";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useCommentListStyles } from "./styles";
import "quill-emoji/dist/quill-emoji.css";
import compress from "compress-base64";
import quillEmoji from "quill-emoji";
const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

const Image = Quill.import("formats/image");

const ATTRIBUTES = ["height", "width", "src", "ondblclick"];

class CustomImage extends Image {
  static formats(domNode) {
    return ATTRIBUTES.reduce((formats, attribute) => {
      const copy = { ...formats };

      if (domNode.hasAttribute(attribute)) {
        copy[attribute] = domNode.getAttribute(attribute);
      }

      return copy;
    }, {});
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(
  {
    "formats/emoji": EmojiBlot,
    "modules/emoji-shortname": ShortNameEmoji,
    "modules/emoji-toolbar": ToolbarEmoji,
    "modules/emoji-textarea": TextAreaEmoji,
    "formats/image": CustomImage,
  },
  true
);

async function getBase64(file) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject();
    };
    reader.onload = (event) => {
      compress(event.target.result, {
        width: 1000,
        height: 1000,
        type: "image/png", // default
        max: 200, // max size
        min: 20, // min size
        quality: 0.8,
      }).then((result) => {
        resolve(result);
      });
    };
  });
}

function imageHandler() {
  const range = this.quill.getSelection();
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.click();
  input.onchange = async () => {
    const file = input.files[0];
    if (/^image\//.test(file.type)) {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 1) {
        alert("File too large. 1MB is a limit");
        return;
      }
      const result = await getBase64(file);
      if (result) {
        this.quill.insertEmbed(
          range.index,
          "image",
          result,
          Quill.sources.USER
        );
        this.quill.formatText(range.index, range.index + 1, "width", "400px");
        this.quill.formatText(
          range.index,
          range.index + 1,
          "ondblclick",
          "window.open(this.src)"
        );
      }
    } else {
      alert("Couldn't upload image. Please try later");
    }
  };
}
const modules = {
  toolbar: {
    container: [
      [{ size: ["small", "normal", "large", "huge"] }],
      ["bold", "italic", "underline", { color: [] }, { background: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
    ],
    handlers: {
      image: imageHandler,
    },
  },
};

const validateCreation = (values) => {
  const errors = {};
  if (!values.body) {
    errors.body = ["Comment body is required"];
  }
  if (values.body) {
    let html = values.body;
    let string = html.replace(/<[^>]*>?/gm, "");
    let trimmed = string.trim();
    if (trimmed.length < 4) {
      errors.body = ["Comment must be longer then 4 characters"];
    }
  }
  return errors;
};
export default ({
  activityStreamId,
  comment,
  redirectAfterSave,
  onCancel,
  handleFormSubmit,
  blocker = "NONE",
}) => {
  const classes = useCommentListStyles();
  const handlePaste = (e) => {
    if (
      e.clipboardData &&
      e.clipboardData.files &&
      e.clipboardData.files.length > 0
    ) {
      e.preventDefault();
    }
  };
  return (
    <Formik
      initialValues={{ body: comment ? comment.body : "" }}
      validate={validateCreation}
      onSubmit={(values) => {
        handleFormSubmit({
          body: values.body,
          activityStreamId,
          comment,
          blocker,
        });
      }}
      redirect={() => redirectAfterSave()}
    >
      {({ errors, isValid, dirty, isSubmitting }) => {
        return (
          <Form className={classes.replyForm}>
            <label className={classes.labelInInputReplies}>
              Enter your comment below
            </label>
            <Field name="body">
              {({ field }) => {
                return (
                  <div
                    onPaste={handlePaste}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => e.preventDefault()}
                    className={classes.field}
                  >
                    <ReactQuill
                      name="body"
                      value={field.value || ""}
                      onChange={field.onChange(field.name)}
                      modules={{ ...modules, "emoji-textarea": true }}
                    />
                  </div>
                );
              }}
            </Field>
            {errors.body ? (
              <div>
                {errors.body.map((error, index) => (
                  <p className={classes.validation} key={index}>
                    {error}
                  </p>
                ))}
              </div>
            ) : null}
            <div style={{ display: "flex" }}>
              <button
                className={classes.buttonSaveCancel}
                type="submit"
                disabled={!(isValid && dirty) || isSubmitting}
              >
                SAVE
              </button>
              <button className={classes.buttonSaveCancel} onClick={onCancel}>
                CANCEL
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

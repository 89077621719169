import React, { useEffect, useState } from "react";
import LabelledField from "./labelledField";
import PropTypes from "prop-types";
import { useDataProvider, Error } from "react-admin";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { convertDateFormat } from "../views/common/convertDate";
const useStyles = makeStyles({
  title: {
    marginRight: "auto",
    fontSize: "30px",
    marginBottom: "5px",
  },
  header: {
    marginBottom: "30px",
  },
});

const TasksHeader = ({ title, currentWorkPeriod, ...props }) => {
  const [workPeriodInfo, setWorkPeriodInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  useEffect(() => {
    dataProvider
      .getOne("workPeriodInfo", {
        workPeriodId: currentWorkPeriod.id,
      })
      .then(({ data }) => {
        setWorkPeriodInfo(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, currentWorkPeriod]);
  if (error) {
    return (
      <Error error={error} errorInfo="Failed to load work period information" />
    );
  }
  if (loading) {
    return <CircularProgress />;
  }
  return (
    <React.Fragment>
      <section className={classes.header}>
        <h1 className={classes.title} data-cy="view-title">
          {title}
        </h1>
        <h4 className="text" data-cy="work-period">{`${convertDateFormat(
          currentWorkPeriod.startDate
        )} - ${convertDateFormat(currentWorkPeriod.endDate)}`}</h4>
      </section>
      <LabelledField label="Remark" {...props}>
        {workPeriodInfo && workPeriodInfo.comment && workPeriodInfo.comment.text
          ? `${workPeriodInfo.comment.text}`
          : "You don't have any remark for current work period."}
      </LabelledField>
    </React.Fragment>
  );
};
TasksHeader.propTypes = {
  title: PropTypes.string.isRequired,
  currentWorkPeriod: PropTypes.object.isRequired,
};
export default TasksHeader;

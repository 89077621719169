import React from "react";

export default function GuideIcon() {
  return (
    <svg
      className="Navbar__icon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#4a4c4c"
        fillRule="evenodd"
        d="M20.522 17.605a62.73 62.73 0 0 1-1.881.11c-1.813.089-3.539.174-5.381.735V6.154a12.092 12.092 0 0 1 2.631-.627v5.516h2V5.355c.28-.016.557-.033.848-.047a71.535 71.535 0 0 0 1.783-.102v12.399zm-9.262.845c-1.842-.561-3.567-.646-5.38-.735-.61-.029-1.235-.06-1.88-.11V5.206c.614.045 1.205.074 1.783.102 1.96.096 3.659.179 5.477.846V18.45zM21.416 3.119c-.969.103-1.887.148-2.775.192-2.085.102-4.054.198-6.216 1.02l-.165.063-.164-.063c-2.163-.822-4.132-.918-6.216-1.02-.887-.044-1.805-.089-2.774-.192L2 3v16.417l.893.095c1.026.11 1.974.156 2.89.201 1.881.092 3.524.189 5.261.785v.83h2.435v-.83c1.735-.596 3.379-.693 5.26-.785.916-.045 1.863-.091 2.889-.201l.894-.095V3l-1.106.119z"
      />
    </svg>
  );
}

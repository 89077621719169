import React from "react";
import { makeStyles } from "@material-ui/core";
import Dropdown from "tombac-ui/dist/components/Dropdown/Dropdown";
import { convertDateFormat } from "../common/convertDate";

const dropdownStyles = makeStyles({
  root: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  dropdown: {
    width: "300px",
    marginBottom: "20px",
    "& ul": {
      overflow: "scroll",
      height: "50vh",
    },
  },
});

export default ({ options, selectedWorkPeriod, onWorkPeriodChange }) => {
  const classes = dropdownStyles();
  const rendererWorkPeriod = ({ startDate, endDate }) => (
    <section>
      {`${convertDateFormat(startDate)} - ${convertDateFormat(endDate)}`}
    </section>
  );
  return (
    <section className={classes.root}>
      {selectedWorkPeriod !== undefined && (
        <Dropdown
          placeholder="Select work period"
          value={selectedWorkPeriod}
          options={options}
          onChange={onWorkPeriodChange}
          optionRenderer={rendererWorkPeriod}
          valueRenderer={rendererWorkPeriod}
          className={classes.dropdown}
        />
      )}
    </section>
  );
};

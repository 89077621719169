import React from "react";
import Logo from "./logo";
import { AppBar, Toolbar } from "@material-ui/core";
import { colors } from "./theme";
import IconButton from "@material-ui/core/IconButton";
import { MuiThemeProvider, makeStyles } from "@material-ui/core/styles";
import { TomTomTheme } from "./theme";
import Menu from "../views/login/menu";
import GuideIcon from "../icons/GuideIcon";
import Sidebar from "../components/sidebar";
import Tooltip from "tombac-ui/dist/components/Tooltip/Tooltip";
import { Notification } from "react-admin";
import Notifications from "../components/notifications";
import { ClearCacheProvider, useClearCacheCtx } from "react-clear-cache";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    minHeight: "100vh",
    backgroundColor: "#fafafa",
    position: "relative",
  },
  appFrame: {
    display: "flex",
    flexDirection: "column",
  },
  contentWithSidebar: {
    display: "flex",
    flex: 1,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
    padding: theme.spacing(3),
    marginLeft: "5em",
    overflow: "auto",
    minHeight: "100vh",
  },
  appBar: {
    backgroundColor: colors.white,
    color: colors.accent.labelBlue,
    flexGrow: 1,
    boxShadow: "0 2px 12px 0 rgba(0,0,0,.1)",
  },
  title: {
    marginRight: "auto",
    color: "#000",
    fontFamily: "Gotham-Medium, Gotham",
    fontSize: "22px",
    fontWeight: "400",
  },
}));

export const AppLayout = (props) => (
  <ClearCacheProvider duration={3600000} filename="meta.json">
    <MuiThemeProvider theme={TomTomTheme}>
      <TomTomWorkLayout {...props} />
    </MuiThemeProvider>
  </ClearCacheProvider>
);

const TomTomWorkLayout = ({ children, ...props }) => {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
  if (!isLatestVersion) {
    emptyCacheStorage();
  }
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <CustomAppBar {...props} />
        <main className={classes.contentWithSidebar}>
          <Sidebar {...props} />
          <div className={classes.content}>{children}</div>
        </main>
        <Notification />
      </div>
    </div>
  );
};

const CustomAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar {...props} position="static" className={classes.appBar}>
      <Toolbar>
        <Logo />
        <span className={classes.title}>WORK</span>
        <Notifications {...props} />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://confluence.tomtomgroup.com/display/MCPSREDOC/TomTom+Work+Documentation"
        >
          <Tooltip content={() => "User Guide"}>
            {({ ref, open, close }) => (
              <IconButton
                edge="end"
                aria-label="help outline"
                aria-haspopup="true"
                ref={ref}
                onMouseEnter={open}
                onMouseLeave={close}
              >
                <GuideIcon />
              </IconButton>
            )}
          </Tooltip>
        </a>
        <Menu />
      </Toolbar>
    </AppBar>
  );
};

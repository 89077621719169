import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";

const styles = () =>
  createStyles({
    container: {
      display: "flex",
      margin: "20px 0px"
    },
    label: {
      color: "#94999d",
      textTransform: "uppercase",
      fontWeight: "bold",
      marginRight: "20px"
    },
    value: { wordWrap: "wrap", width: "60%" }
  });

export default withStyles(styles)(({ label, value, classes, ...props }) => {
  return (
    <section className={classes.container}>
      <div className={classes.label}>{label}</div>
      <div className={classes.value}>{props.children}</div>
    </section>
  );
});

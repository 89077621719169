import { colors } from "./theme";
import { makeStyles } from "@material-ui/core/styles";

export const iconButtonStyles = {
  iconButton: {
    fill: colors.blacks.brandBlack,
    backgroundColor: "inherit",
    border: "none",
    alignSelf: "center",
    padding: "5px",
    transition: "all .2s ease-in-out",
    "&:active": {
      border: "none",
    },
    "&:focus": {
      border: "none",
      outline: "0",
    },
    "&:hover": {
      transform: "scale(1.1)",
    },
    cursor: "pointer",
  },
};
export const useStyles = makeStyles({
  ...iconButtonStyles,
});

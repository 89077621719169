import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Button from "tombac-ui/dist/components/Button/Button";
import PropTypes from "prop-types";
import { colors } from "../../ui/theme";
const useStyles = makeStyles({
  root: {
    color: colors.white,
    backgroundColor: colors.accent.darkBlue,
    fontFamily: "Gotham Bold",
    fontSize: "14px",
    textTransform: "uppercase",
    "&:hover:enabled": {
      backgroundColor: colors.accent.lightBlue,
      cursor: "pointer",
    },
  },
});

const PrimaryButton = ({ children, className, disabled, onClick }, props) => {
  const classes = useStyles();
  return (
    <Button
      type="submit"
      className={classnames(classes.root, className)}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};
PrimaryButton.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default PrimaryButton;

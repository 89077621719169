import Details from "./details";
import { useHistory } from "react-router";
import React from "react";

export default (props) => {
  const history = useHistory();
  const activityStreamId = props.match.params.id;
  const handleRedirect = () => history.push("/#/tasks");
  return (
    <>
      <Details
        open={true}
        selectedTask={{ activityStreamId }}
        redirect={handleRedirect}
        {...props}
      />
    </>
  );
};

import React from "react";
import { connect } from "react-redux";
import { userLogout } from "react-admin";
import Button from "@material-ui/core/Button";
import ExitIcon from "@material-ui/icons/PowerSettingsNew";

const LogoutButton = ({ userLogout, ...rest }) => (
  <Button
    id="logoutButton"
    onClick={userLogout}
    size="small"
    color="inherit"
    {...rest}
  >
    <ExitIcon />
  </Button>
);
// Force react-admin to redirect to /login
const redirect = "/login";
const customUserLogout = () => userLogout(redirect);
export default connect(undefined, { userLogout: customUserLogout })(
  LogoutButton
);

import { getDecodedToken, isTokenExpired } from "./decodeToken";
import { msalApp } from "./msAuthUtils";

function getHeaders(passToken) {
  if (passToken) {
    return new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    });
  }
  return new Headers({ "Content-Type": "application/json" });
}

const callPermissions = (url, body, passToken) =>
  fetch(
    new Request(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: getHeaders(passToken),
    })
  )
    .then((response) => {
      if (response.status >= 500) {
        throw new Error("Something went wrong, please try again later");
      }
      if (response.status < 200 || response.status >= 300) {
        return response.json().then(({ message }) => {
          throw new Error(message);
        });
      }
      return response.json();
    })
    .then(({ token }) => {
      localStorage.setItem("token", token);
      return Promise.resolve();
    });

const getPermissions = () => {
  const decodedToken = getDecodedToken(localStorage.getItem("token"));
  const permissions = decodedToken.Permissions;
  return permissions !== undefined
    ? Promise.resolve(permissions)
    : Promise.reject();
};

let authenticating = false;

export default {
  login: (params) => {
    const { login, password } = params;
    if (login !== undefined && password !== undefined) {
      return callPermissions("/permissions/login", {
        login: login,
        password: password,
        app: "task-director",
      });
    } else {
      return msalApp.login();
    }
  },
  refresh: (params) => {
    if (isTokenExpired()) {
      return Promise.reject();
    }
    return callPermissions(
      "/permissions/token",
      {
        app: "task-director",
      },
      true
    );
  },
  logout: (params) => {
    localStorage.clear();
    return Promise.resolve();
  },
  checkAuth: (params) => {
    if (localStorage.getItem("token")) {
      return Promise.resolve();
    }
    let account = msalApp.getAccount();
    if (account && account.userName) {
      authenticating = new Promise((resolve, reject) =>
        msalApp
          .silentLogin(account.userName)
          .then(({ idToken }) =>
            callPermissions("/permissions/mstoken", {
              userName: idToken.preferredName,
              idToken: idToken.rawIdToken,
              app: "task-director",
            })
          )
          .then(() => {
            resolve();
            authenticating = false;
            return Promise.resolve();
          })
          .catch((e) => {
            reject(e);
          })
      );
    }
    return Promise.reject();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: (params) => {
    if (authenticating) {
      return authenticating.then(() => getPermissions());
    }
    return getPermissions();
  },
};

msalApp.handleRedirectCallback((error, response) => {
  if (error) {
    console.error("Callback error: ", error);
    throw new Error("Something went wrong, please try again later");
  }
});

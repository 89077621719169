import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import EmptyState from "../img/no-results.svg";

const styles = () =>
  createStyles({
    container: {
      backgroundColor: "#fff",
      display: "flex",
      flexDirection: "column",
      padding: "30px",
      textAlign: "center",
      height: "40rem",
    },
    text: { display: "block", margin: "5px", textAlign: "center" },
  });

export default withStyles(styles)(({ header, children, classes, ...props }) => {
  return (
    <Paper className={classes.container}>
      <h2 className="h2">We couldn't find any task</h2>
      <p className={classes.text}>
        Maybe you don't have any task assigned for this week
      </p>
      <p className={classes.text}>In any doubts, contact your manager</p>
      <img src={EmptyState} alt="empty-state" />
    </Paper>
  );
});

import React, { useEffect, useRef } from "react";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (props, previousPath) => {
    if (previousPath !== props.location.pathname && window.utag) {
      window.utag.view({
        page_url: window.location.href,
        ...options,
      });
    }
  };

  const HOC = (props) => {
    const previousPath = usePrevious(props.location.pathname);
    useEffect(() => trackPage(props, previousPath));

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

import React from "react";
import ListItem from "@material-ui/core/ListItem";
import classnames from "classnames";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import { CommentEditForm, EditActions } from "./edit";
import { colors } from "../../ui/theme";
import ReplyIcon from "@material-ui/icons/Reply";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ReplyCreate from "./reply";
import { ADD_BLOCKER, REMOVE_BLOCKER } from "../blockerFlag";
import FlagIcon from "tombac-ui/dist/components/Icon/ClosureIcon";
import { convertDateWithTimeFormat } from "../../views/common/convertDate";
import { useCommentListStyles } from "./styles";
import Permalink from "./copyPermalink";
import { getCommentsChildren } from "../activityStreams/details";
import { setActiveThread } from "../../actions/commentsAction";
import { useDispatch } from "react-redux";
import { useNotify } from "react-admin";
import { creatingComment } from "./list";
export default (props) => {
  const {
    redirectToCommentId,
    comment,
    setEditFormVisible,
    activityStreamId,
  } = props;
  const [openReply, setOpenReply] = React.useState(false);
  const classes = useCommentListStyles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const commentId = comment.id;
  const commentDate = convertDateWithTimeFormat(new Date(comment.createdAt));
  const commentEdited = comment.edited;
  const isEditable = comment.editable;
  const highlightComment = redirectToCommentId === comment.id;
  const handleOpeningReply = () => {
    setOpenReply(!openReply);
  };
  const handleSubmit = async (req) => {
    const response = await creatingComment(req);
    if (response.status === 200) {
      try {
        await handleSavingReply(req.comment);
        notify("Element created");
      } catch (e) {
        notify(
          e.message ? e.message : "An error occurred. Please refresh page."
        );
        console.log(e);
      }
    } else {
      console.log("failed to create a comment");
      notify("Couldn't add comment");
    }
  };
  const handleSavingReply = async ({ id: commentId }) => {
    handleOpeningReply();
    try {
      const response = await getCommentsChildren(activityStreamId, commentId);
      dispatch(setActiveThread(response));
    } catch (e) {
      notify(e.message ? e.message : "Couldn't save reply");
    }
  };

  const handleOpeningActiveThread = async () => {
    try {
      const response = await getCommentsChildren(
        props.activityStreamId,
        commentId
      );
      dispatch(setActiveThread(response));
    } catch (e) {
      notify(e.message ? e.message : "Couldn't get comment children");
      console.log(e);
    }
  };
  const getCommentStatus = (status) => {
    switch (status) {
      case ADD_BLOCKER:
        return (
          <span className={classes.blocker}>
            <FlagIcon color={colors.primary.lightRed} width="20" />
            Blocker added
          </span>
        );
      case REMOVE_BLOCKER:
        return (
          <span className={classes.blocker}>
            <FlagIcon color={colors.blacks.lightBlack} width="20" />
            Blocker removed
          </span>
        );
      default:
        return null;
    }
  };
  return (
    <>
      {props.editFormVisible[comment.id] ? (
        <CommentEditForm
          editFormVisible={props.editFormVisible}
          setEditFormVisible={setEditFormVisible}
          commentId={comment.id}
          activityStreamId={props.activityStreamId}
          handleUpdate={props.handleUpdate}
          {...props}
        />
      ) : (
        <div
          data-comment={comment.id}
          style={{
            borderLeft: `2px solid ${colors.border}`,
            marginBottom: "15px",
          }}
        >
          <ListItem
            key={commentId}
            className={classnames(
              classes.li,
              highlightComment && classes.highlight
            )}
          >
            <ListItemText
              primary={
                <div>
                  <span style={{ fontWeight: "bold" }}>
                    {comment.userFullName}
                  </span>
                  <span>
                    {" "}
                    added a comment - <span>{commentDate}</span>
                    <span className={classes.edited}>
                      {commentEdited && " - edited"}
                    </span>
                  </span>
                </div>
              }
              secondary={
                <div className={classes.commentContainer}>
                  {getCommentStatus(comment.blocker)}
                  <div
                    className={classes.secondary}
                    dangerouslySetInnerHTML={{
                      __html: comment.body,
                    }}
                  />
                </div>
              }
            />
            {isEditable && (
              <EditActions
                setEditFormVisible={setEditFormVisible}
                commentId={comment.id}
                userId={comment.userFullName}
                isBlockerComment={comment.blocker}
                {...props}
              />
            )}
          </ListItem>
          <div>
            <div className={classes.childrenExpansion}>
              <button
                className={classes.buttonReply}
                onClick={handleOpeningReply}
                style={{ color: colors.teals.teal }}
              >
                <ReplyIcon width={16} height={20} />
                Reply
              </button>
              <Permalink
                activityStreamId={props.activityStreamId}
                commentId={commentId}
                selectedTaskId={props.selectedTaskId}
              />

              {comment.hasChildren && (
                <div style={{ fontSize: "12px" }}>
                  <button
                    className={classes.buttonReply}
                    style={{
                      color: colors.indigo.brandIndigo,
                      marginLeft: "50px",
                    }}
                    onClick={handleOpeningActiveThread}
                  >
                    <QuestionAnswerIcon width={16} height={20} /> View full
                    conversation
                  </button>
                </div>
              )}
            </div>
            <div>
              {openReply && (
                <div style={{ width: "510px", marginLeft: "10px" }}>
                  <ReplyCreate
                    {...props}
                    comment={{ id: comment.id }}
                    activityStreamId={props.activityStreamId}
                    onCancel={handleOpeningReply}
                    handleFormSubmit={handleSubmit}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNotify } from "react-admin";
import Modal from "tombac-ui/dist/components/Modal/Modal";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { REMOVE_BLOCKER, ADD_BLOCKER } from "./index";
import ReplyCreate from "../../components/comments/reply";

const useBlockerCommentFormStyles = makeStyles({
  container: {
    padding: "1em 0 3em 0 ",
    width: "inherit",
  },
  title: {
    marginBottom: "10px",
  },
  subtitle: {
    marginTop: "10px",
  },
});

export default ({
  isDialogOpen,
  setDialogOpen,
  activityStream,
  setRefreshAsDetails,
  refreshASDetails,

  setRefreshComments,
  refreshComments,
  ...props
}) => {
  const notify = useNotify();
  const classes = useBlockerCommentFormStyles();

  const handleBlockerFlagUpdate = async (body) => {
    const token = localStorage.getItem("token");
    const headers = new Headers({ Accept: "application/json" });
    headers.append("content-type", `application/json`);
    headers.append("authorization", `Bearer ${token}`);
    const response = await fetch(
      `/activityStreams/${activityStream.id}/blocked`,
      {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      setRefreshAsDetails(!refreshASDetails);
      setRefreshComments(!refreshComments);
      setDialogOpen(!isDialogOpen);
      notify("Task status successfully updated");
    } else {
      setDialogOpen(!isDialogOpen);
      notify(`Can't update task status`);
      console.log("failed to update");
    }
  };
  const blocker = activityStream.blocked ? REMOVE_BLOCKER : ADD_BLOCKER;
  return (
    <Modal isOpen={isDialogOpen} onClose={() => setDialogOpen(!isDialogOpen)}>
      <div className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          {activityStream.blocked ? "Remove" : "Add"} blocker flag and provide a
          comment
        </Typography>
        <Divider />
        <Typography variant="body1" className={classes.subtitle}>
          {activityStream.name}
        </Typography>
      </div>

      <ReplyCreate
        {...props}
        blocker={blocker}
        onCancel={() => setDialogOpen(!isDialogOpen)}
        activityStreamId={activityStream.id}
        handleFormSubmit={handleBlockerFlagUpdate}
      />
    </Modal>
  );
};

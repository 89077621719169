import React, { useState, useEffect } from "react";
import { useDataProvider, Error } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import CloseIcon from "tombac-ui/dist/components/Icon/CancelIcon";

import { useStyles as useIconButtonStyles } from "../../ui/iconButtonStyles";
import { colors } from "../../ui/theme";
import { BlockerFlag, BlockerCommentForm } from "../blockerFlag";
import Tooltip from "tombac-ui/dist/components/Tooltip/Tooltip";
import FlagIcon from "tombac-ui/dist/components/Icon/ClosureIcon";
import Button from "tombac-ui/dist/components/Button/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import classnames from "classnames";
import { getDecodedToken } from "../../security/decodeToken";

const useActivityStreamToolbarStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: colors.white,
    padding: "16px 32px",
    borderBottom: `1px solid ${colors.border}`,
    display: "flex",
    alignItems: "center",
  },
  blockerFlag: {
    marginLeft: "10px",
  },
  buttons: {
    backgroundColor: colors.white,
    color: colors.blacks.brandBlack,
    cursor: "pointer",
    "&:hover:enabled": {
      backgroundColor: colors.blacks.shadowBlack,
    },
  },
  btnContainer: {
    width: "min-content",
  },
  watching: {
    color: colors.teals.lightTeal,
  },
  typography: {
    marginRight: "auto",
  },
}));
const ActivityStreamToolbar = ({
  handleClose,
  record,
  setRefreshAsDetails,
  refreshASDetails,
  ...props
}) => {
  const [isBlockerFormOpen, setBlockerFormOpen] = useState(false);
  const classes = useActivityStreamToolbarStyles();
  const buttonClasses = useIconButtonStyles();
  const handleOpenCommentForm = () => {
    setBlockerFormOpen(!isBlockerFormOpen);
  };
  return (
    <div className={classes.title}>
      <Typography variant="h2" className={classes.typography}>
        Activity stream
        <span className={classes.blockerFlag}>
          {record.blocked && <BlockerFlag />}
        </span>
      </Typography>
      {record && record.id && (
        <WatchingButton
          record={record}
          refreshASDetails={refreshASDetails}
          setRefreshAsDetails={setRefreshAsDetails}
        />
      )}
      <Tooltip
        content={() => {
          return record.blocked
            ? "Unblock activity stream"
            : "Block activity stream";
        }}
        placement="bottom"
      >
        {({ ref, open, close }) => (
          <div
            ref={ref}
            onMouseEnter={open}
            onMouseLeave={close}
            className={classes.btnContainer}
          >
            <Button
              icon={<FlagIcon color="#000" width="24" height="24" />}
              onClick={handleOpenCommentForm}
              className={classes.buttons}
            >
              {record.blocked ? "Remove blocker" : "Add blocker"}
            </Button>
          </div>
        )}
      </Tooltip>
      <button className={buttonClasses.iconButton} onClick={handleClose}>
        <CloseIcon color="#000" width="32" height="32" />
      </button>
      {isBlockerFormOpen && (
        <BlockerCommentForm
          activityStream={record}
          isDialogOpen={isBlockerFormOpen}
          setDialogOpen={setBlockerFormOpen}
          setRefreshAsDetails={setRefreshAsDetails}
          refreshASDetails={refreshASDetails}
          {...props}
        />
      )}
    </div>
  );
};

const WatchingButton = ({
  refreshASDetails,
  setRefreshAsDetails,
  ...props
}) => {
  let { id } = props.record;
  const dataProvider = useDataProvider();
  const [isFetching, setLoading] = useState(true);
  const [watching, setWatching] = useState(false);
  const [error, setError] = useState();
  const classes = useActivityStreamToolbarStyles();
  const decodedToken = getDecodedToken();
  const userId = decodedToken.UserId;
  useEffect(() => {
    dataProvider
      .getOne("watchers", {
        featureId: id,
        userId,
        featureType: "activityStream",
      })
      .then(({ data }) => {
        if (data && data.featureType) {
          setWatching(true);
        } else {
          setWatching(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, refreshASDetails, id, userId]);
  if (isFetching) return <CircularProgress />;
  if (error) return <Error error={error} errorInfo="Failed fetch comment" />;
  const handleStartWatching = () => {
    if (watching) {
      dataProvider
        .delete("watchers", {
          featureId: id,
          userId,
          data: { featureType: "activityStream" },
        })
        .then(({ data }) => {
          setRefreshAsDetails(!refreshASDetails);
        })
        .catch((error) => {
          setError(error);
        });
    } else {
      dataProvider
        .create("watchers", {
          featureId: id,
          userId,
          data: { featureType: "activityStream" },
        })
        .then(({ data }) => {
          setRefreshAsDetails(!refreshASDetails);
        })
        .catch((error) => {
          setError(error);
        });
    }
  };
  return (
    <>
      <Tooltip
        content={() => {
          return watching ? "Stop watching" : "Start watching";
        }}
        placement="bottom"
      >
        {({ ref, open, close }) => (
          <div
            ref={ref}
            onMouseEnter={open}
            onMouseLeave={close}
            className={classes.btnContainer}
          >
            <Button
              className={classnames(
                classes.buttons,
                watching && classes.watching
              )}
              icon={<VisibilityIcon />}
              onClick={handleStartWatching}
            >
              {watching ? "Currently watching" : "Not watching"}
            </Button>
          </div>
        )}
      </Tooltip>
    </>
  );
};

export default ActivityStreamToolbar;

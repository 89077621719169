import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import EnhancedTable from "../../components/table";
import TasksEmptyState from "../../components/emptyState";
import TasksHeader from "../../components/tasksHeader";
import { useCurrentWorkPeriod } from "../common/currentPeriod";
import { getDecodedToken } from "../../security/decodeToken";
import { useDataProvider, Error } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import WorkPeriodDropdown from "./workPeriodSelect";
import { tasksHistoryColumns } from "../tasks/listColumns";
import * as qs from "query-string";
import Details from "../../components/activityStreams/details";
import { connect, useSelector } from "react-redux";
import { push } from "connected-react-router";

const useStyles = makeStyles({
  paper: {
    padding: "0px 30px",
    backgroundColor: "inherit",
  },
  container: { display: "flex" },
  workPeriodInfo: {
    width: "50%",
  },
});

const TasksHistory = ({
  defaultWorkPeriod,
  workPeriodsData,
  basePath,
  ...props
}) => {
  const dataProvider = useDataProvider();
  const [currentWorkPeriod, setCurrentWorkPeriod] = useState(defaultWorkPeriod);
  const [tasks, setTasks] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const classes = useStyles();
  useEffect(() => {
    const decodedToken = getDecodedToken();
    const userId = decodedToken.UserId;
    dataProvider
      .getList("tasks", {
        userId,
        workPeriodId: currentWorkPeriod.id,
        pagination: { page: 1, perPage: 100 },
        sort: { field: "startDate", order: "DESC" },
      })
      .then(({ data }) => {
        setTasks(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, currentWorkPeriod]);
  const handleWorkPeriodChange = (value) => {
    setCurrentWorkPeriod(value);
    props.push(`/tasksHistory?workPeriodId=${value.id}`);
  };
  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return <Error error={error} errorInfo="Failed to load tasks" />;
  }
  const reversedWorkPeriods = [...workPeriodsData].reverse();
  return (
    <Paper className={classes.paper}>
      {currentWorkPeriod && (
        <React.Fragment>
          <div className={classes.container}>
            <div className={classes.workPeriodInfo}>
              <TasksHeader
                title="Assigned tasks history"
                currentWorkPeriod={currentWorkPeriod}
                {...props}
              />
            </div>
            <WorkPeriodDropdown
              options={reversedWorkPeriods}
              onWorkPeriodChange={handleWorkPeriodChange}
              selectedWorkPeriod={currentWorkPeriod}
            />
          </div>
          {tasks && tasks.length > 0 ? (
            <EnhancedTable
              records={tasks}
              columns={tasksHistoryColumns}
              basePath={basePath}
              workPeriodId={currentWorkPeriod.id}
            />
          ) : (
            <TasksEmptyState />
          )}
        </React.Fragment>
      )}
    </Paper>
  );
};

const TasksWrapper = (props) => {
  const [workPeriodId, setWorkPeriodId] = useState(undefined);
  const [taskId, setTaskId] = useState(undefined);
  const {
    currentWorkPeriod,
    workPeriodsData,
    loading,
  } = useCurrentWorkPeriod();
  useEffect(() => {
    const params = qs.parse(props.location.search);
    setWorkPeriodId(undefined);
    setTaskId(undefined);
    if (params.workPeriodId && workPeriodsData) {
      let workPeriod = workPeriodsData.find(
        (el) => el.id === params.workPeriodId
      );
      setWorkPeriodId(workPeriod);
    }
    if (params.selectedTaskId) {
      setTaskId(params.selectedTaskId);
    }
  }, [workPeriodsData, props.location]);

  const selectedTask = useSelector(
    (state) => state.admin.resources.tasks.data[taskId] || {}
  );
  if (loading) {
    return <CircularProgress />;
  }
  return (
    <>
      <TasksHistory
        defaultWorkPeriod={workPeriodId ? workPeriodId : currentWorkPeriod}
        workPeriodsData={workPeriodsData}
        {...props}
      />
      {!!taskId &&
      selectedTask.constructor === Object &&
      Object.keys(selectedTask).length > 0 ? (
        <Details
          open={!!taskId}
          selectedTask={selectedTask}
          selectedTaskId={taskId}
          {...props}
        />
      ) : null}
    </>
  );
};
export default connect(undefined, { push })(TasksWrapper);

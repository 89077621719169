import React from "react";
import LinkIcon from "@material-ui/icons/Link";
import Tooltip from "tombac-ui/dist/components/Tooltip/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import { colors } from "../../ui/theme";

const InstructionLink = ({ data, id }) => {
  const useStyles = makeStyles({
    icon: {
      verticalAlign: "middle",
      marginLeft: "5px",
      fill: colors.blacks.brandBlack,
    },
  });
  const classes = useStyles();
  return (
    <Link target="_blank" rel="noopener noreferrer" href={data[id].link}>
      <Tooltip
        content={() => `${data[id].link.substring(0, 150)}...`}
        placement="right"
      >
        {({ ref, open, close }) => (
          <LinkIcon
            ref={ref}
            onMouseEnter={open}
            onMouseLeave={close}
            className={classes.icon}
          />
        )}
      </Tooltip>
    </Link>
  );
};
export default InstructionLink;

import React from "react";
import LinkIcon from "@material-ui/icons/Link";
import { colors } from "../../ui/theme";
import Tooltip from "tombac-ui/dist/components/Tooltip/Tooltip.js";
import { useNotify } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useCommentListStyles } from "./styles";
import classnames from "classnames";

const usePermalinkStyles = makeStyles({
  hiddenInput: {
    height: 0,
    position: "absolute",
    zIndex: -1,
  },
  permalinkButton: {
    color: colors.cadmiums.darkCadmium,
  },
  container: {
    backgroundColor: colors.background,
    zIndex: "2",
    margin: "50px 300px 15px 30px",
    height: "85%",
    flexDirection: "column",
  },
});

export default function Permalink(props) {
  const classes = usePermalinkStyles();
  const buttonClasses = useCommentListStyles();
  const notify = useNotify();
  const { commentId, activityStreamId } = props;

  const basePathTTWork = `http://work.maps.tomtomgroup.com/#/activityStreams/${activityStreamId}/show?commentId=${commentId}`;
  const basePathTPlanner = `http://teamplanner.maps.tomtomgroup.com/#/activityStreams/${activityStreamId}/show?commentId=${commentId}`;

  function copyToClipboard(containerId) {
    if (document.selection) {
      let range = document.body.createTextRange();
      range.moveToElementText(document.getElementById(containerId));
      range.select().createTextRange();
      document.execCommand("copy");
    } else if (window.getSelection) {
      let range = document.createRange();
      range.selectNode(document.getElementById(containerId));
      window.getSelection().removeAllRanges(range);
      window.getSelection().addRange(range);
      document.execCommand("copy");
      notify("Copied");
    }
  }
  return (
    <div>
      <form>
        <div>
          <Tooltip
            content={() => "Copy the full url of this comment "}
            placement="bottom"
          >
            {({ ref, open, close }) => (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  copyToClipboard(commentId);
                }}
                className={classnames(
                  buttonClasses.buttonReply,
                  classes.permalinkButton
                )}
                ref={ref}
                onMouseEnter={open}
                onMouseLeave={close}
              >
                <LinkIcon width={16} height={20} />
                Copy links
              </button>
            )}
          </Tooltip>
          <div id={commentId} className={classes.hiddenInput}>
            {"Open link in: "}
            <a href={basePathTTWork}>TT Work</a>
            {" / "}
            <a href={basePathTPlanner}>Team Planner</a>
          </div>
        </div>
      </form>
    </div>
  );
}

import React, { useEffect } from "react";
import { useLogin, useNotify, Notification } from "react-admin";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { TomTomTheme } from "../../ui/theme";
import Logo from "../../ui/logo";
import { colors } from "../../ui/theme";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import PasswordInput from "./passwordInput";
import { Form, useField } from "react-final-form";
import TextField from "@material-ui/core/TextField";
import Button from "../../components/buttons";
import Divider from "@material-ui/core/Divider";
import Image from "../../img/loginPage-1.jpg";
import { CircularProgress } from "@material-ui/core";
import * as qs from "query-string";

const useStyles = makeStyles({
  root: {
    height: "100vh",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  input: { width: "40%", minWidth: "200px", height: "70px" },
  image: {
    backgroundImage: `url(${Image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  typography: {
    margin: "2em",
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
  },
  loginActions: {
    display: "flex",
    width: "50%",
    margin: "10px",
  },
  loginButton: {
    display: "flex",
    width: "215px",
    margin: "auto",
    backgroundColor: colors.primary.brandRed,
    "&:hover": {
      backgroundColor: colors.primary.lightRed,
      cursor: "pointer",
      "&:enabled": {
        backgroundColor: colors.primary.lightRed,
      },
    },
  },
  msLoginButton: {
    display: "flex",
    backgroundImage: `url(./microsoft.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundColor: colors.white,
    backgroundSize: "100%",
    border: "none",
    width: "215px",
    height: "40px",
    margin: "auto",
    cursor: "pointer",
  },
  divider: {
    width: "60%",
    alignSelf: "center",
  },
});
const UnconnectedLogin = ({ loading, ...props }) => {
  const login = useLogin();
  const notify = useNotify();
  const classes = useStyles();
  const loginAction = (errorMsg) => (auth) => {
    login(
      auth,
      props.location.state ? props.location.state.nextPathname : "/"
    ).catch((e) => notify((e && e.message) || errorMsg));
  };
  useEffect(() => {
    const params = qs.parse(props.location.search);
    if (params.loginMS === null) {
      login({}, "/");
    }
  });
  if (loading) return <CircularProgress />;
  return (
    <MuiThemeProvider theme={TomTomTheme}>
      <Grid container component="main" className={classes.root}>
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          className={classes.container}
        >
          <Form onSubmit={loginAction("Invalid credentials")}>
            {(props) => (
              <form className={classes.form} onSubmit={props.handleSubmit}>
                <Logo />
                <Typography className={classes.typography}>
                  Welcome to TomTom Work
                </Typography>
                <UsernameInput name="login" label="Username" />
                <PasswordInput name="password" classes={classes} />
                <section className={classes.loginActions} data-cy="loginBtn">
                  <Button className={classes.loginButton}>Sign in</Button>
                </section>
              </form>
            )}
          </Form>
          <Divider className={classes.divider} />
          <Form
            onSubmit={loginAction(
              "Something went wrong, please try again later"
            )}
          >
            {(props) => (
              <form className={classes.form} onSubmit={props.handleSubmit}>
                <section className={classes.loginActions} data-cy="msLoginBtn">
                  <button className={classes.msLoginButton} />
                </section>
              </form>
            )}
          </Form>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Grid>
        <Notification />
      </Grid>
    </MuiThemeProvider>
  );
};

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
export default UnconnectedLogin;

const UsernameInput = ({ name, label }) => {
  const classes = useStyles();
  const {
    input: { onChange },
    meta: { touched, error },
  } = useField(name);
  return (
    <TextField
      name={name}
      label={label}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      autoFocus
      variant="outlined"
      margin="normal"
      required
      placeholder="Username"
      type="text"
      data-cy="username"
      className={classes.input}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AccountCircle
              size={30}
              style={{ fill: colors.blacks.lightBlack }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ padding: "20px" }}
    >
      {"Copyright © "}
      {new Date().getFullYear()}
      <Link color="inherit" href="https://www.tomtom.com/">
        {" "}
        TomTom International BV
      </Link>
      {"."} All rights reserved.
    </Typography>
  );
}

import React from "react";
import SentimentDissatisfied from "@material-ui/icons/SentimentDissatisfied";
import { colors } from "../ui/theme";
import Button from "tombac-ui/dist/components/Button/Button";

const defaultStyles = {
  section: {
    textAlign: "center",
    color: colors.blacks.lightBlack,
    padding: "100px",
    position: "relative",
  },
  icon: {
    fontSize: 160,
  },
  backButton: {
    root: {
      padding: "10px",
      color: colors.accent.brandBlue,
    },
    backIcon: {
      marginRight: "0.5em",
      width: "20px",
      height: "20px",
    },
  },
};

const ErrorFallback = ({ error }) => {
  setTimeout(() => {
    window.location.href = "/";
  }, 5000);
  fetch(
    new Request("/error", {
      method: "POST",
      body: JSON.stringify({
        error: error ? error.message : "",
        path: window.location.href,
        stack: error ? error.stack : "",
      }),
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      }),
    })
  );
  return (
    <div style={defaultStyles.section} role="alert">
      <SentimentDissatisfied style={defaultStyles.icon} />
      <h1>Something went wrong</h1>
      <h3>
        Engineering team was notified, if this error persists please contact the
        help desk
      </h3>
      <h3>Redirecting to the main page in five seconds</h3>
      <Button
        buttonStyle={defaultStyles.backButton}
        onClick={() => (window.location.href = "/")}
      >
        RELOAD
      </Button>
    </div>
  );
};

export default ErrorFallback;

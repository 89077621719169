import React, { useCallback, useEffect, useState } from "react";
import { useDataProvider, useNotify } from "react-admin";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import { colors } from "../../../ui/theme";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  enabled: {
    color: colors.accent.brandBlue,
  },
  disabled: {
    color: colors.blacks.lightBlack,
  },
  grid: {
    marginLeft: "5px",
    minWidth: "100px",
  },
}));

const Toggle = ({ activityStreamId, enableAutoClaim, handleActiveTask }) => {
  const classes = useStyles();
  return (
    <>
      <Grid
        component="label"
        container
        alignItems="center"
        spacing={0.5}
        className={classes.grid}
      >
        <Grid item>Off</Grid>
        <Grid item>
          <Switch
            checked={enableAutoClaim}
            onChange={handleActiveTask}
            color="primary"
            name={activityStreamId}
          />
        </Grid>
        <Grid item> On</Grid>
      </Grid>
      <div>
        Auto-Claim is{" "}
        <span className={enableAutoClaim ? classes.enabled : classes.disabled}>
          {enableAutoClaim ? "enabled" : "disabled"}
        </span>
      </div>
    </>
  );
};

const UserSwitch = ({
  activityStreamId,
  activeTask,
  setActiveTask,
  reload,
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [timerId, setTimerId] = useState(null);

  const handleActiveTask = useCallback(
    async (value) => {
      await dataProvider
        .create("userActivity", {
          activityStreamId: activityStreamId,
          data: {
            activityStreamId: activityStreamId,
            active: value,
          },
        })
        .then(({ data }) => {
          if (data) {
            if (data.active) setActiveTask(data.activityStreamId);
            else setActiveTask("");
          }
        })
        .catch((err) => {
          console.log(err.message);
          notify("Something went wrong, try again");
        });
    },
    [activityStreamId, dataProvider, notify, setActiveTask]
  );

  const getActiveTask = useCallback(async () => {
    await dataProvider
      .getOne("userActivity", {})
      .then(({ data }) => {
        if (data) {
          if (data.active) setActiveTask(data.activityStreamId);
          else setActiveTask("");
        }
      })
      .catch((err) => {
        notify("Something went wrong, try again");
      });
  }, [dataProvider, notify, setActiveTask]);

  useEffect(() => {
    getActiveTask();
  }, [getActiveTask, reload]);

  useEffect(() => {
    if (activeTask === activityStreamId && timerId === null) {
      const interval = setInterval(() => {
        handleActiveTask(null);
      }, 60000);
      setTimerId(interval);
    }
    if (activeTask !== activityStreamId && timerId !== null) {
      clearInterval(timerId);
      setTimerId(null);
    }
  }, [handleActiveTask, activeTask, activityStreamId, timerId, setTimerId]);

  useEffect(() => {
    return () => clearInterval(timerId);
  }, [timerId]);

  return (
    <>
      <Toggle
        activityStreamId={activityStreamId}
        handleActiveTask={() =>
          handleActiveTask(!(activityStreamId === activeTask))
        }
        enableAutoClaim={activityStreamId === activeTask}
        reload={reload}
      />
    </>
  );
};

const AutoServeSwitch = ({
  activityStreamId,
  activeTask,
  setActiveTask,
  reload,
}) => {
  const [autoClaimToggle, setAutoClaimToggle] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const getAvailableToggle = useCallback(async () => {
    if (!activityStreamId) return;
    await dataProvider
      .getOne("autoclaim", {
        activityStreamId: activityStreamId,
        data: { activityStreamId: activityStreamId },
      })
      .then(({ data }) => {
        if (data) {
          setAutoClaimToggle(!!data.enable);
        }
      })
      .catch((err) => {
        notify("Something went wrong, try again");
      });
  }, [activityStreamId, notify, dataProvider]);

  useEffect(() => {
    getAvailableToggle();
  }, [getAvailableToggle, reload]);

  if (!activityStreamId) return null;
  return autoClaimToggle ? (
    <UserSwitch
      activityStreamId={activityStreamId}
      activeTask={activeTask}
      setActiveTask={setActiveTask}
      reload={reload}
    />
  ) : (
    <div>Auto-Claim is not available</div>
  );
};

export default AutoServeSwitch;

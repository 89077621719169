import React, { useEffect, useState } from "react";
import Button from "tombac-ui/dist/components/Button/Button";
import CommentIcon from "@material-ui/icons/Message";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import * as qs from "query-string";
import CommentItem from "./commentItem";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ReplyCreate from "./reply";
import { useCommentListStyles } from "./styles";
import { getCommentsChildren } from "../activityStreams/details";
import { setActiveThread } from "../../actions/commentsAction";
import { useNotify } from "react-admin";
import { httpClient } from "../../rest";
const CommentsList = (props) => {
  const classes = useCommentListStyles();
  const notify = useNotify();
  const [isCommentInputVisible, setCommentInputVisible] = useState(false);
  const comments = useSelector(
    (state) => (state.commentsReducer && state.commentsReducer.comments) || []
  );
  const {
    activityStreamId,
    setRefreshComments,
    refreshComments,
    setCommentId,
  } = props;

  const commentsBasePath = `/activityStreams/${activityStreamId}/comments`;
  const handleOpeningStartConversation = () => {
    setCommentInputVisible(!isCommentInputVisible);
  };

  const handleRedirect = () => {
    const { commentId, selectedTaskId, workPeriodId } = qs.parse(
      props.location.search
    );
    setCommentId(undefined);
    setCommentInputVisible(false);
    if (commentId) {
      //remove commentId from query param
      if (selectedTaskId && workPeriodId) {
        const stringified = qs.stringify({ selectedTaskId, workPeriodId });
        props.location.search = `?${stringified}`;
      }
      return props.location.pathname + props.location.search;
    } else {
      setRefreshComments(!refreshComments);
      return props.location.pathname + props.location.search;
    }
  };
  const handleCreatingComment = async (req) => {
    const response = await creatingComment(req);
    if (response.status === 200) {
      try {
        handleRedirect();
        notify("Element created");
      } catch (e) {
        notify(
          e.message ? e.message : "An error occurred. Please refresh page."
        );
        console.log(e);
      }
    } else {
      console.log("failed to create a comment");
      notify("Couldn't add comment");
    }
  };

  const renderComment = () =>
    comments && comments.length > 0 ? (
      comments.map((comment) => {
        return (
          <List className={classes.root}>
            <Comment
              {...props}
              key={comment.id}
              comment={comment}
              resource="comments"
              basePath={commentsBasePath}
              setCommentInputVisible={setCommentInputVisible}
              activityStreamId={activityStreamId}
              setCommentId={setCommentId}
            />
          </List>
        );
      })
    ) : (
      <Typography variant="body2">
        There are no comments yet on this activity stream.
      </Typography>
    );
  return (
    <>
      {isCommentInputVisible ? (
        <div style={{ width: "520px" }}>
          <ReplyCreate
            {...props}
            handleFormSubmit={handleCreatingComment}
            onCancel={handleOpeningStartConversation}
          />
        </div>
      ) : (
        <Button
          icon={<CommentIcon className={classes.iconStartConversation} />}
          className={classes.buttonStartConversation}
          onClick={() => {
            setCommentInputVisible(!isCommentInputVisible);
          }}
        >
          Start a discussion
        </Button>
      )}
      {renderComment()}
    </>
  );
};

export const Comment = (props) => {
  const [editFormVisible, setEditFormVisible] = useState({});
  const activeThread = useSelector(
    (state) =>
      (state.commentsReducer && state.commentsReducer.activeThread) || {}
  );
  const { redirectToCommentId, comment } = props;
  useEffect(() => {
    if (redirectToCommentId) {
      const commentElement = document.querySelector(
        `[data-comment='${redirectToCommentId}']`
      );
      if (commentElement !== null) {
        commentElement.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "start",
        });
      }
    }
  }, [redirectToCommentId]);
  const isActiveThread =
    activeThread &&
    activeThread.id === comment.id &&
    activeThread.childComments;
  return (
    <div>
      <>
        {isActiveThread ? (
          <>
            <CommentItem
              editFormVisible={editFormVisible}
              setEditFormVisible={setEditFormVisible}
              {...props}
              comment={activeThread}
            />
            <CommentThread
              thread={activeThread}
              editFormVisible={editFormVisible}
              setEditFormVisible={setEditFormVisible}
              {...props}
            />
          </>
        ) : (
          <CommentItem
            editFormVisible={editFormVisible}
            setEditFormVisible={setEditFormVisible}
            {...props}
          />
        )}
      </>
    </div>
  );
};
export default CommentsList;

const CommentThread = ({ thread, ...props }) => {
  const [isThreadOpen, setThreadOpen] = useState(null);
  const [replyToThread, setReplyToThread] = useState(false);
  const dispatch = useDispatch();
  const notify = useNotify();
  useEffect(() => {
    setThreadOpen(true);
  }, [thread]);
  const classes = useCommentListStyles();
  const hasChildren = (thread) =>
    thread && thread.childComments && thread.childComments.length > 0;
  const handleOpeningReply = () => {
    setReplyToThread(!replyToThread);
  };
  const handleSavingReply = async ({ id: commentId }) => {
    handleOpeningReply();
    try {
      const response = await getCommentsChildren(
        props.activityStreamId,
        commentId
      );
      dispatch(setActiveThread(response));
    } catch (e) {
      console.log(e);
      notify("Couldn't add reply");
    }
  };
  const handleReply = async (req) => {
    const response = await creatingComment(req);
    if (response.status === 200) {
      try {
        await handleSavingReply(req.comment);
        notify("Element created");
      } catch (e) {
        notify(
          e.message ? e.message : "An error occurred. Please refresh page."
        );
        console.log(e);
      }
    } else {
      console.log("failed to create a comment");
      notify("Couldn't add comment");
    }
  };
  return (
    <>
      {isThreadOpen && (
        <div className={classes.commentBorderLink}>
          {thread.childComments.length > 0 && (
            <ArrowUpwardIcon
              onClick={() => {
                setThreadOpen(!isThreadOpen);
              }}
              className={classes.arrowButton}
            />
          )}
          {thread.childComments.map((item) => {
            return (
              <div style={{ marginLeft: "50px" }}>
                <CommentItem {...props} comment={item} />
                {hasChildren(item) ? (
                  <CommentThread thread={item} {...props} />
                ) : null}
              </div>
            );
          })}

          {thread.childComments && thread.childComments.length > 0 && (
            <Button
              icon={<CommentIcon className={classes.icon} />}
              className={classes.buttonAddForThread}
              onClick={() => {
                handleOpeningReply();
              }}
            >
              Continue this thread
            </Button>
          )}
          {replyToThread && (
            <div style={{ width: "510px", marginLeft: "3em" }}>
              <ReplyCreate
                {...props}
                comment={{ id: thread.id }}
                activityStreamId={props.activityStreamId}
                onCancel={handleOpeningReply}
                handleFormSubmit={handleReply}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
export const creatingComment = async ({
  body,
  comment,
  activityStreamId,
  blocker,
}) => {
  const variables = {
    body: body,
    parentId: comment ? comment.id : undefined,
    blocker: blocker,
    activityStreamId: activityStreamId,
  };
  return await httpClient(`/activityStreams/${activityStreamId}/comments`, {
    method: "POST",
    body: JSON.stringify(variables),
  });
};

import decodeJwt from "jwt-decode";

export function getDecodedToken(token) {
  try {
    if (token === undefined) {
      token = localStorage.getItem("token");
    }
    return decodeJwt(token);
  } catch (error) {
    return error;
  }
}

export function isTokenExpired() {
  const token = localStorage.getItem("token");
  if (!token) {
    return true;
  }
  const { exp } = decodeJwt(token);
  return Date.now() >= exp * 1000;
}

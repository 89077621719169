import {
  GET_COMMENTS,
  UPDATE_COMMENT,
  REMOVE_COMMENT,
  CLEAN_COMMENTS,
  SET_ACTIVE_THREAD,
  GET_FILTERED_COMMENTS,
} from "../actions/commentsAction";

// export default (previousState = [], { type, payload }) => {
//   switch (type) {
//     case GET_COMMENTS:
//       return [...previousState, ...payload.data];
//     case REMOVE_COMMENT:
//       return previousState.filter((item) => item.id !== payload.id);
//     case UPDATE_COMMENT:
//       let index = previousState.findIndex((item) => item.id === payload.id);
//       previousState[index] = payload.data;
//       return previousState;
//     case CLEAN_COMMENTS:
//       return [];
//     default:
//       return previousState;
//   }
// };

const updateCommentInThread = (id, updatedComment, thread) => {
  if (id === thread.id) {
    thread.body = updatedComment.body;
  } else {
    thread.childComments.map((comment) =>
      updateCommentInThread(id, updatedComment, comment)
    );
  }
};

const deleteCommentInThread = (id, thread) => {
  thread.childComments.forEach((comment, index) => {
    if (comment.id === id) {
      thread.childComments.splice(index, 1);
      if (thread.childComments.length === 0) {
        thread.hasChildren = false;
      }
    } else {
      deleteCommentInThread(id, comment);
    }
  });
};
export default (
  previousState = { comments: [], activeThread: {} },
  { type, payload }
) => {
  switch (type) {
    case GET_COMMENTS:
      return {
        ...previousState,
        comments: [...previousState.comments, ...payload.data],
      };
    case GET_FILTERED_COMMENTS:
      return {
        ...previousState,
        comments: [...payload.data],
      };
    case REMOVE_COMMENT:
      const commentToRemove = previousState.comments.findIndex(
        (item) => item.id === payload.id
      );
      if (commentToRemove === -1) {
        deleteCommentInThread(payload.id, previousState.activeThread);
      } else {
        previousState.comments.splice(commentToRemove, 1);
      }
      return {
        ...previousState,
        comments: [...previousState.comments],
        activeThread: { ...previousState.activeThread },
      };
    case UPDATE_COMMENT:
      const index = previousState.comments.findIndex(
        (item) => item.id === payload.id
      );
      if (index === -1) {
        updateCommentInThread(
          payload.id,
          payload.data,
          previousState.activeThread
        );
      } else {
        previousState.comments[index] = payload.data;
      }
      return {
        ...previousState,
        comments: [...previousState.comments],
        activeThread: { ...previousState.activeThread },
      };
    case CLEAN_COMMENTS:
      return { ...previousState, comments: [] };
    case SET_ACTIVE_THREAD:
      return { ...previousState, activeThread: { ...payload.data } };
    default:
      return previousState;
  }
};

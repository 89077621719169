import React, { useEffect } from "react";
import { Admin, Resource } from "react-admin";
import dataProvider from "./rest";
import { TomTomTheme } from "./ui/theme";
import { AppLayout } from "./ui/layout";
import login from "./views/login";
import TasksList from "./views/tasks/list";
import TasksHistory from "./views/tasksHistory/list";
import tasks from "./reducers/tasksReducer";
import commentsReducer from "./reducers/commentsReducer";
import notifications from "./reducers/notificationsReducer";
import notificationsCount from "./reducers/notificationsCountReducer";
import workPeriodInfo from "./reducers/workPeriodInfoReducer";
import customSagas from "./customSagas";
import security from "./security";
import { withTracker } from "./withTracker";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/errorFallback";
import authProvider from "./security/authProvider";
import ActivityStreamShow from "./components/activityStreams/show";

const App = () => {
  useEffect(() => {
    authProvider.refresh().catch((e) => authProvider.logout());
  }, []);
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Admin
        customSagas={[customSagas]}
        customReducers={{
          tasks,
          workPeriodInfo,
          notifications,
          notificationsCount,
          commentsReducer,
        }}
        dataProvider={dataProvider}
        layout={AppLayout}
        theme={TomTomTheme}
        title={null}
        {...security}
        loginPage={withTracker(login.loginPage)}
        logoutButton={login.logoutButton}
      >
        {(permissions = []) => [
          <Resource name="tasks" list={withTracker(TasksList)} />,
          <Resource name="tasksHistory" list={withTracker(TasksHistory)} />,
          <Resource name="budgetCycles" />,
          <Resource name="workPeriods" />,
          <Resource name="workPackages" />,
          <Resource name="notifications" />,
          <Resource name="nonProjectActivities" />,
          <Resource name="activityStreams" show={ActivityStreamShow} />,
          <Resource name="comments" />,
          <Resource name="users" />,
          <Resource name="autoclaim" />,
          <Resource name="userActivity" />,
        ]}
      </Admin>
    </ErrorBoundary>
  );
};
export default App;
